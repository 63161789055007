import React, { useState, Fragment, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  likeOrUnlikePost,
  fetchComments,
  postComment,
  editPost,
  deletePost,
  deleteComment,
  editComment,
  resharePost,
  fetchLikes,
  reportPost 
} from '../redux/actions/post';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { format } from 'date-fns';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { FixedSizeList as List } from 'react-window';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Divider, IconButton, Typography, Modal, Backdrop, Fade, TextField, Button, MenuItem } from "@mui/material";
import {
  ChatBubbleOutlineOutlined,
  ChatBubble,
  RocketLaunchOutlined
} from "@mui/icons-material";
import { Popover, Transition } from '@headlessui/react';
import {
  EllipsisHorizontalIcon,
} from '@heroicons/react/24/outline';

import FlexBetween from "../components/FlexBetween";
import Friend from "../components/Friend";
import WidgetWrapper from "../components/WidgetWrapper";
import Avatar from "../components/Layout/Avatar";

const PostWidgetRedux = ({
  postId,
  postUserId,
  owner,
  name,
  description,
  profileType,
  location,
  images = [],
  videos = [],
  avatar,
  likes = [],
  comments = [],
  totalComments,
  createdAt,
  onShareSuccess,
  resharedBy,
  reshareDescription,
  reshareAvatar,
  link,
  isLikedByCurrentUser,
}) => {
  const [isComments, setIsComments] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false); 
  const [reportReason, setReportReason] = useState(''); 
  const [reportDescription, setReportDescription] = useState(''); 
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { seller } = useSelector((state) => state.seller);
  const loggedInUserId = user?._id;
  const loggedInSellerId = seller?._id;
  const [scrollPosition, setScrollPosition] = useState(0); 
  const [showReshareInput, setShowReshareInput] = useState(false);
  const [reshareInput, setReshareInput] = useState('');
  const [isReshare, setIsReshare] = useState(!!resharedBy);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editedDescription, setEditedDescription] = useState(description);
  const [editedReshareDescription, setEditedReshareDescription] = useState(reshareDescription);
  const [commentUpdateTrigger, setCommentUpdateTrigger] = useState(0);
  const formattedDate = format(new Date(createdAt), "PPP");
  const [currentPage, setCurrentPage] = useState(1);
  const [allCommentsLoaded, setAllCommentsLoaded] = useState(false);
  const currentProfileType = sessionStorage.getItem('currentProfile');
  const [currentProfileId, setCurrentProfileId] = useState(currentProfileType === 'User' ? user?._id : seller?._id);
  const [commentText, setCommentText] = useState('');
  const [fetchedComments, setFetchedComments] = useState([]);
  const [editingCommentId, setEditingCommentId] = useState(null);
  const [newCommentText, setNewCommentText] = useState('');
  const currentProfile = useSelector(state => state.user.currentProfile);
  const commentProfileType = currentProfileType === 'User' ? 'User' : 'Shop';
  const commentsData = useSelector((state) => state.posts.comments[postId] || []);
  const postData = useSelector((state) => state.posts.posts.find((post) => post._id === postId));
  const [newComments, setNewComments] = useState([]);
  const navigate = useNavigate();
  const likesData = useSelector((state) => state.posts.likes[postId] || {}); 
  const [likeCount, setLikeCount] = useState(Object.keys(likes).length);
  const [hasMoreComments, setHasMoreComments] = useState(comments.length < totalComments);
  const [currentCommentPage, setCommentCurrentPage] = useState(2);
  const isInitiallyLiked = useMemo(() => {
    // Ensure that likes is an array before calling some
    return Array.isArray(likes) && likes.some((like) => like.likedBy === currentProfileId);
  }, [likes, currentProfileId]);
  
  const [isLiked, setIsLiked] = useState(isInitiallyLiked); 
  
  const toggleEditMode = useCallback((e) => {
    if (e) e.preventDefault();
    if (!isEditMode) {
      setScrollPosition(window.scrollY);
    } else {
      window.scrollTo(0, scrollPosition);
    }

    setIsEditMode(!isEditMode);

    if (isEditMode) {
      setEditedDescription(description);
    }
  }, [description, isEditMode, scrollPosition]);

  const handleCancelReshare = () => {
    setShowReshareInput(false); 
    setReshareInput(''); 
  };
  const handleShowReshareInput = () => {
    setReshareInput('');
    setShowReshareInput(true);
  };

  const handleCancelEdit = () => {
    setEditedDescription(description); 
    setEditedReshareDescription(reshareDescription); 
    setIsEditMode(false); 
  };
  const loadMoreComments = useCallback(() => {
    if (!hasMoreComments) return;
  
    const nextPage = currentCommentPage;
  
    dispatch(fetchComments(postId, nextPage))
      .then((response) => {
        const moreComments = response.data.comments; 
  
        if (moreComments.length === 0) {
          setHasMoreComments(false); 
        } else {
          setNewComments((prev) => [...prev, ...moreComments]);
          setCommentCurrentPage((prev) => prev + 1); 
        }
      })
      .catch((error) => {
        console.error("Error loading more comments:", error);
        toast.error('Failed to load more comments');
      });
  }, [currentCommentPage, dispatch, postId, hasMoreComments]);
  

  const handleLikeOrUnlikePost = () => {
    const wasLiked = isLiked; // Track if the post was previously liked
    const updatedLikeCount = wasLiked ? likeCount - 1 : likeCount + 1; // Adjust like count based on action
  
    // Update UI optimistically
    setIsLiked(!wasLiked); // Toggle the liked state
    setLikeCount(updatedLikeCount); // Update the like count in the UI
  
    // Dispatch the action to the server
    dispatch(likeOrUnlikePost(postId, currentProfileType))
      .then(() => {
        // Optionally handle success
      })
      .catch((error) => {
        // Revert the UI changes if there is an error
        setIsLiked(wasLiked); // Revert liked state
        setLikeCount(likeCount); // Revert like count
        toast.error('Failed to update like status');
      });
  };

  const handleCommentSubmit = (e) => {
    e.preventDefault();
  
    if (!commentText.trim()) {
      toast.error('Comment cannot be empty');
      return;
    }
  
    // Create the optimistic comment with user/shop data
    const newComment = {
      _id: Math.random().toString(36).substr(2, 9), // Temporary ID
      commentText: commentText,
      profileType: commentProfileType,
      userId: commentProfileType === 'User' ? { ...user } : null,
      shopId: commentProfileType === 'Shop' ? { ...seller } : null,
      createdAt: new Date().toISOString(),
    };
  
    // Optimistically add the comment to state
    setNewComments((prev) => [...prev, newComment]);
    setCommentText(''); // Clear input field
  
    // Dispatch the action to post the comment
    dispatch(postComment(postId, commentText, currentProfileType))
      .then((serverComment) => {
        // Merge server comment with optimistic comment to retain user/shop info
        const updatedComment = {
          ...newComment, // Keep optimistic data like user/shop info
          ...serverComment, // Override with server response
        };
  
        // Replace the optimistic comment with the updated comment
        setNewComments((prev) =>
          prev.map((comment) =>
            comment._id === newComment._id ? updatedComment : comment
          )
        );
  
       
      })
      .catch(() => {
        // Remove the optimistic comment if the submission fails
        setNewComments((prev) =>
          prev.filter((comment) => comment._id !== newComment._id)
        );
        toast.error('Failed to post comment');
      });
  };
  
  


  const handleEditSubmit = (e) => {
    e.preventDefault();
    dispatch(editPost(postId, editedDescription, reshareDescription))
      .catch((error) => {
        toast.error('Failed to edit post');
      });
    setIsEditMode(false);
  };

  const handleDeletePost = (e) => {
    e.preventDefault();
    dispatch(deletePost(postId)).catch((error) => {
      toast.error('Failed to delete post');
    });
  };

  const handleDeleteComment = (commentId) => {
    dispatch(deleteComment(postId, commentId))
      .catch((error) => {
        toast.error('Failed to delete comment');
      });
    setCommentUpdateTrigger(prev => prev + 1);
  };

  const handleEditComment = (commentId, newText) => {
    dispatch(editComment(postId, commentId, newText))
      .catch((error) => {
        toast.error('Failed to edit comment');
      });
    setCommentUpdateTrigger(prev => prev + 1);
  };

  const handleSharePost = () => {
    const reshareData = {
      profileType: currentProfile,
      reshareDescription: reshareInput, 
    };
    dispatch(resharePost(postId, reshareData))
      .then(() => {
        setShowReshareInput(false);
        setReshareInput(''); 
        toast.success('Post reshared successfully');
      })
      .catch((error) => {
        toast.error('Failed to reshare post');
      });
  };

  const handleReportPost = () => {
    if (!reportReason || !reportDescription) {
      toast.error('Please provide both a reason and a description.');
      return;
    }

    const reportData = {
      postId,
      reason: reportReason,
      description: reportDescription,
    };

    dispatch(reportPost(reportData))
      .then(() => {
        setShowReportModal(false);
        setReportReason('');
        setReportDescription('');
        toast.success('Post reported successfully.');
      })
      .catch((error) => {
        toast.error('Failed to report post');
      });
  };

  const renderEditForm = () => (
    <form onSubmit={handleEditSubmit} style={{ width: '100%' }}>
      <textarea
        autoFocus
        className="edit-description-textarea"
        value={isReshare ? editedReshareDescription : editedDescription}
        onChange={(e) => isReshare ? setEditedReshareDescription(e.target.value) : setEditedDescription(e.target.value)}
        rows={3}
        style={{
            width: '100%',
            background: 'none',
            border: 'none',
            fontSize: 'inherit', 
            color: 'inherit', 
            lineHeight: 'inherit', 
            resize: 'none', 
            outline: 'none', 
          }}
      />
      <div className="flex justify-end space-x-2 mt-3">
        <button type="submit" className="inline-flex items-center justify-center rounded-md bg-green-600 px-4 py-2 text-sm font-semibold text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 transition duration-200 ease-in-out">
          Save Changes
        </button>
        <button type="button" onClick={handleCancelEdit} className="inline-flex items-center justify-center rounded-md bg-gray-300 px-4 py-2 text-sm font-semibold text-gray-700 hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 transition duration-200 ease-in-out">
          Cancel
        </button>
      </div>
    </form>
  );

  const parseHashtags = useCallback((text) => {
    const words = text.split(' ');
    return words.map((word, index) => {
      if (word.startsWith('#')) {
        const hashtag = word.substring(1);
        return (
          <span
            key={index}
            style={{ color: 'green', cursor: 'pointer' }}
            onClick={() => navigate(`/hashtag/${hashtag}`)}
          >
            {word}
          </span>
        );
      }
      return `${word} `;
    });
  }, [navigate]);

  const renderLinkPreview = (link) => {
    if (!link || !link.url) return null;

    return (
      <a href={link.url} target="_blank" rel="noopener noreferrer" className="link-post-preview-link">
        <Box className="link-post-preview-container">
          {link.image && (
            <img src={link.image} alt={link.title} className="link-post-preview-image" />
          )}
          <Box className="link-post-preview-content">
            <Typography variant="h6" component="div" className="link-post-preview-title">
              {link.title}
            </Typography>
            <Typography variant="body2" color="textSecondary" className="link-post-preview-description">
              {link.description}
            </Typography>
            <Typography variant="body2" color="primary" className="link-post-preview-url">
              {link.url}
            </Typography>
          </Box>
        </Box>
      </a>
    );
  };

  return (
    <>
      <ToastContainer />
      <WidgetWrapper m="2rem 0" className="post-widget-wrapper">
        {(loggedInUserId === owner || loggedInSellerId === owner) && (
          <Popover className="post-widget-popover">
            <Popover.Button className="post-widget-popover-button">
              <EllipsisHorizontalIcon className="post-widget-icon" aria-hidden="true" />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="post-widget-transition-enter"
              enterFrom="post-widget-transition-enter-from"
              enterTo="post-widget-transition-enter-to"
              leave="post-widget-transition-leave"
              leaveFrom="post-widget-transition-leave-from"
              leaveTo="post-widget-transition-leave-to"
            >
              <Popover.Panel className="post-widget-popover-panel">
                <div className="post-widget-popover-content">
                  <a
                    href="#"
                    onClick={(e) => toggleEditMode(e)} 
                    className="post-widget-popover-item"
                  >
                    Edit
                  </a>
                  <a
                    href="#"
                    onClick={(e) => handleDeletePost(e)} 
                    className="post-widget-popover-item"
                  >
                    Delete
                  </a>
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>
        )}
  
        {(loggedInUserId !== owner && loggedInSellerId !== owner) && (
          <Popover className="post-widget-popover">
            <Popover.Button className="post-widget-popover-button">
              <EllipsisHorizontalIcon className="post-widget-icon" aria-hidden="true" />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="post-widget-transition-enter"
              enterFrom="post-widget-transition-enter-from"
              enterTo="post-widget-transition-enter-to"
              leave="post-widget-transition-leave"
              leaveFrom="post-widget-transition-leave-from"
              leaveTo="post-widget-transition-leave-to"
            >
              <Popover.Panel className="post-widget-popover-panel">
                <div className="post-widget-popover-content">
                  <a
                    href="#"
                    onClick={() => setShowReportModal(true)} 
                    className="post-widget-popover-item"
                  >
                    Report
                  </a>
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>
        )}
  
        {resharedBy && (
          <div className="post-widget-reshare">
            <div className="post-widget-reshare-header">
              <img 
                src={reshareAvatar || 'default-avatar-url'} 
                alt={resharedBy}
                className="post-widget-reshare-avatar" 
              />
              <span className="post-widget-reshare-by">Reshared by {resharedBy}</span>
            </div>
            {reshareDescription && (
              <div className="post-widget-reshare-description">
                {isEditMode && isReshare ? renderEditForm() : reshareDescription}
              </div>
            )}
          </div>
        )}
  
        {showReshareInput && (
          <div className="post-widget-reshare-container">
            <textarea
              className="post-widget-reshare-textarea"
              value={reshareInput}
              onChange={(e) => setReshareInput(e.target.value)}
              placeholder="Add a description to your reshare..."
            />
            <div className="post-widget-reshare-buttons-container">
            <button className="post-widget-reshare-button post-widget-cancel-button" onClick={handleCancelReshare}>
              Cancel
            </button>
            <button className="post-widget-reshare-button" onClick={handleSharePost}>
              Share
            </button>
            </div>
          </div>
        )}
        
        <Friend
          friendId={isReshare ? postUserId : owner} 
          friendType={profileType}
          name={name}
          subtitle={location}
          avatar={avatar}
        />
        <div className="post-widget-date">
          {formattedDate}
        </div>
        <div>
          {isEditMode && !isReshare ? renderEditForm() : (
            <Typography color="#1f9c29" sx={{ mt: "1rem" }}>
              {parseHashtags(description)}
            </Typography>
          )}
        </div>
        
        {link && renderLinkPreview(link)}
  
        <div className="post-widget-media-container">
          {images.length > 0 && (
            <div className="post-widget-image-gallery">
              {images.map((image, index) => (
                <LazyLoadImage
                  key={index}
                  width="100%"
                  height="auto"
                  alt={`post image ${index + 1}`}
                  className="post-widget-image"
                  src={image.url}
                  placeholderSrc="path/to/placeholder/image.jpg"
                />
              ))}
            </div>
          )}
          {videos.length > 0 && (
            <div className="post-widget-video-gallery">
              {videos.map((video, index) => (
                <video key={index} width="100%" controls className="post-widget-video">
                  <source src={video.url} type="video/mp4" /> 
                  Your browser does not support the video tag.
                </video>
              ))}
            </div>
          )}
        </div>
  
        <FlexBetween mt="0.25rem">
          <FlexBetween gap="1rem">
            <FlexBetween gap="0.3rem">
              <IconButton onClick={handleLikeOrUnlikePost}>
                {isLiked ? (
                  <img src="https://res.cloudinary.com/dzlopmfj8/image/upload/v1724845531/icon-BuzzBolt-02_ernlpk.png" alt="Liked" className="post-widget-like-icon" />
                ) : (
                  <img src="https://res.cloudinary.com/dzlopmfj8/image/upload/v1724845557/icon-BuzzBolt-01_qhtx6n.png" alt="Not Liked" className="post-widget-like-icon" />
                )}
              </IconButton>
              <Typography>{likeCount}</Typography>
            </FlexBetween>
  
            <FlexBetween gap="0.3rem">
            <IconButton onClick={() => setIsComments(!isComments)}>
              {(comments.length + newComments.length) > 0 ? (
                <ChatBubble />
              ) : (
                <ChatBubbleOutlineOutlined />
              )}
            </IconButton>
            <Typography>
              {totalComments}
            </Typography>

          </FlexBetween>
          </FlexBetween>
  
          <IconButton onClick={handleShowReshareInput} title="Share Post">
      <RocketLaunchOutlined />
    </IconButton>
        </FlexBetween>
        {isComments && (
  <>
  <List
    height={Math.min((comments.length + newComments.length) * 80, 740)} 
    itemCount={comments.length + newComments.length}
    itemSize={70}
    width="100%"
    className="post-widget-comments-list"
  >
    {({ index, style }) => {
      const comment = index < comments.length ? comments[index] : newComments[index - comments.length];

      return (
        <div key={comment?._id} style={style} className="post-widget-comment-container">
          <div className="post-widget-comment-avatar-container">
            <img
              className="post-widget-comment-avatar"
              src={comment?.profileType === 'Shop'
                ? comment?.shopId?.avatar?.url
                : comment?.userId?.avatar?.url}
              alt={comment?.profileType === 'Shop'
                ? comment?.shopId?.name || 'Shop Name'
                : comment?.userId?.name || 'User Name'}
            />
          </div>
          <div className="post-widget-comment-content">
            <span className="post-widget-comment-author">
              {comment?.profileType === 'Shop' ? comment?.shopId.name : comment?.userId?.name}
            </span>
            <div className="post-widget-comment-text-box">
              {editingCommentId === comment?._id ? (
                <input
                  type="text"
                  className="post-widget-comment-edit-input"
                  value={newCommentText}
                  onChange={(e) => setNewCommentText(e.target.value)}
                />
              ) : (
                <span className="post-widget-comment-text">{comment?.commentText}</span>
              )}
            </div>

            {((comment?.profileType === 'User' && comment.userId && loggedInUserId === comment.userId._id) ||
              (comment?.profileType === 'Shop' && comment.shopId && loggedInSellerId === comment.shopId._id)) && (
              <Popover className="post-widget-comment-popover">
                <Popover.Button>
                  <EllipsisHorizontalIcon className="post-widget-comment-options-icon" aria-hidden="true" />
                </Popover.Button>
                <Transition
                  as={Fragment}
                  enter="post-widget-transition-enter"
                  enterFrom="post-widget-transition-enter-from"
                  enterTo="post-widget-transition-enter-to"
                  leave="post-widget-transition-leave"
                  leaveFrom="post-widget-transition-leave-from"
                  leaveTo="post-widget-transition-leave-to"
                >
                  <Popover.Panel className="post-widget-comment-options-panel">
                    <div className="post-widget-comment-options-container">
                      <button
                        onClick={() => {
                          setEditingCommentId(comment?._id);
                          setNewCommentText(comment?.commentText);
                        }}
                        className="post-widget-comment-option-button"
                      >
                        Edit
                      </button>
                      <button
                        onClick={() => handleDeleteComment(comment?._id)}
                        className="post-widget-comment-option-button"
                      >
                        Delete
                      </button>
                    </div>
                  </Popover.Panel>
                </Transition>
              </Popover>
            )}

            {editingCommentId === comment?._id && (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  handleEditComment(editingCommentId, newCommentText);
                  setEditingCommentId(null);
                  setNewCommentText('');
                }}
                className="post-widget-comment-save-button"
              >
                Save
              </button>
            )}
          </div>
        </div>
      );
    }}
  </List>

  {hasMoreComments && (
    <button onClick={loadMoreComments} className="load-more-comments-button">
      Load More Comments
    </button>
  )}
</>

)}



<div className="post-widget-comment-input-container">
  <div className="post-widget-comment-input-flex">
    <Avatar />
    <div className="post-widget-comment-input-flex-1">
      <form className="post-widget-comment-input-form" onSubmit={handleCommentSubmit}>
        <textarea
          value={commentText}
          onChange={(e) => setCommentText(e.target.value)}
          rows={3}
          className="post-widget-comment-input"
          placeholder="Add your comment..."
        />
        <div className="post-widget-comment-submit-container">
          <button
            type="submit"
            className="post-widget-comment-submit-button"
          >
            POST
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

      </WidgetWrapper>
  
      <Modal
        open={showReportModal}
        onClose={() => setShowReportModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={showReportModal}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              bgcolor: '#333',
              border: '2px solid #000',
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography variant="h6" component="h2">
              Report Post
            </Typography>
            <TextField
              select
              label="Reason"
              value={reportReason}
              onChange={(e) => setReportReason(e.target.value)}
              fullWidth
              margin="normal"
            >
              <MenuItem value="Spam">Spam</MenuItem>
              <MenuItem value="Harassment">Harassment</MenuItem>
              <MenuItem value="Inappropriate Content">Inappropriate Content</MenuItem>
              <MenuItem value="Other">Other</MenuItem>
            </TextField>
            <TextField
              label="Description"
              value={reportDescription}
              onChange={(e) => setReportDescription(e.target.value)}
              fullWidth
              multiline
              rows={4}
              margin="normal"
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleReportPost}
              fullWidth
            >
              Submit Report
            </Button>
          </Box>
        </Fade>
      </Modal>
    </>
  );}

export default React.memo(PostWidgetRedux);




