import React, { useEffect, useState } from 'react';
import Header from "../components/Layout/Header";
import Categories from "../components/Route/Categories/Categories";
import BestDeals from "../components/Route/BestDeals/BestDeals";
import FeaturedProduct from "../components/Route/FeaturedProduct/FeaturedProduct";
import Events from "../components/Sales/Events";
import Footer from "../components/Layout/Footer";
import Sidebar from '../components/Layout/Sidebar';
import SignupCTA from '../components/SignupCTA';
import ShopBySeller from '../components/Shop/ShopBySeller';
import SponsoredProductCarousel from '../components/Sponsored/SponsoredProductsCarousel';
import SponsoredShopsCarousel from '../components/Sponsored/SponsoredShopsCarousel';
import BannerAd from '../components/Sponsored/BannerAd';
import HeroSliderHome from '../components/Route/Hero/HeroSliderHome';
import DualProductAdCarousel from '../components/Sponsored/DualProductAd';
import QuadProductAdCarousel from '../components/Sponsored/QuadProductAdCarousel';
import TopBannerAd from '../components/Sponsored/TopBannerAd';
import EventCarousel from '../components/Events/EventCarousel';
import SponsoredBrandCarousel from '../components/Sponsored/SponsoredBrandCarousel';
import RecommendedFriends from '../components/Followers/RecommendedFriends';
import axios from 'axios';
import { server } from '../server';
import DualProductAdCarouselProps from '../components/Sponsored/DualProductAdCarouselProps';
import SponsoredProductCarouselProps from '../components/Sponsored/SponsoredProductCarouselProps';
import SponsoredShopsCarouselProps from '../components/Sponsored/SponsoredShopsCarouselProps';
import QuadProductAdCarouselProps from '../components/Sponsored/QuadProductCarouselProps';
import SponsoredBrandCarouselProps from '../components/Sponsored/SponsoredBrandCarouselProps';
import BannerAdProps from '../components/Sponsored/BannerAdProps';
import TopBannerAdProps from '../components/Sponsored/TopBannerAdProps';
import useWindowSize from "../hooks/useWindowSize";
import TopBannerAdPropsMobile from '../components/Sponsored/TopBannerAdPropMobile';
import BannerAdPropsMobile from '../components/Sponsored/BannerAdPropsMobile';
import SponsoredProductCarouselPropsMobile from '../components/Sponsored/SponsoredProductsCarouselPropsMobile';
import MixedSponsoredAdsProps from '../components/Sponsored/MixedSponsoredAdsProps';
import BentoCTA from '../components/BentoCTA';
import SponsoredBrandCarouselPropsMobile from '../components/Sponsored/SponsoredBrandCarouselPropsMobile';
import SponsoredShopsCarouselPropsMobile from '../components/Sponsored/SponsoredShopsCarouselPropsMobile';

const HomePage = () => {
  const [productAds, setProductAds] = useState([]);  
  const [shopAds, setShopAds] = useState([]);  
  const [brandAds, setBrandAds] = useState([]);  
  const [customAds, setCustomAds] = useState([]);
  const windowSize = useWindowSize();

  useEffect(() => {
    const fetchSponsoredAds = async () => {
      try {
        const response = await axios.get(`${server}/sponsored/sponsored-sidebar-ads`, { withCredentials: true });
        const allAds = response.data;
        const productAds = allAds.filter(ad => ad.type === 'product');
        const shopAds = allAds.filter(ad => ad.type === 'shop');
        const brandAds = allAds.filter(ad => ad.type === 'brand');
        const customAds = allAds.filter(ad => ad.type === 'custom');

        setProductAds(productAds);
        setShopAds(shopAds);
        setBrandAds(brandAds);
        setCustomAds(customAds); 
      } catch (error) {
        console.error('Error fetching sponsored ads:', error);
      }
    };

    fetchSponsoredAds();
  }, []);

  const topBannerAd = productAds.length > 0 ? productAds[0] : null;
  const bannerAd = productAds.length > 1 ? productAds[1] : null;

  const isMobile = windowSize.width <= 768;

  return (
    <div className="home-page-container">
      
      {bannerAd && (isMobile ? (
                  <BannerAdPropsMobile ad={bannerAd} />
                ) : (
                  <BannerAdProps ad={bannerAd} />
                ))}
      <Header activeHeading={1} />
      
      <Sidebar />
      <div className="home-page-content">
        
      {topBannerAd && (isMobile ? (
              <TopBannerAdPropsMobile ad={topBannerAd} />
            ) : (
              <TopBannerAdProps ad={topBannerAd} />
            ))}
        <HeroSliderHome />
        <ShopBySeller />
        <BentoCTA />
        <div className="marketplace-page-grid-container">
        <div className="marketplace-page-left-container">
        <DualProductAdCarouselProps ads={productAds} />
        {isMobile ? (
                  <SponsoredProductCarouselPropsMobile  ads={productAds} />
                ) : (
                  <SponsoredProductCarouselProps  ads={productAds} />
                )}
        <EventCarousel />
        <RecommendedFriends />
        {isMobile ? (
                  <SponsoredShopsCarouselPropsMobile ads={shopAds} />
                ) : (
                  <SponsoredShopsCarouselProps ads={shopAds} />
                )}
        <QuadProductAdCarouselProps ads={productAds} />
        <Categories />
        <BestDeals />
        {isMobile ? (
                  <SponsoredBrandCarouselPropsMobile ads={brandAds} />
                ) : (
                  <SponsoredBrandCarouselProps ads={brandAds} />
                )}
       
        <FeaturedProduct />
      </div>
     
      <div className="marketplace-page-right-container">
                <MixedSponsoredAdsProps ads={[...productAds, ...brandAds, ...shopAds]} />
                
                
              </div>
              </div>
              </div>
      <Footer />
    </div>
  );
};

export default HomePage;
