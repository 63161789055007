import React, { useState } from "react";
import SavePaymentMethod from "../../components/Payment/SavePaymentMethod";
import OnboardingSteps from "../../components/Shop/OnboardingSteps";
import { useNavigate } from "react-router-dom";
import useWindowSize from "../../hooks/useWindowSize";
import OnboardingStepsMobile from "../../components/Shop/OnboardingStepsMobile";

const ShopPaymentMethodPage = () => {
  const [hasPaymentMethod, setHasPaymentMethod] = useState(false);
  const navigate = useNavigate();
  const windowSize = useWindowSize();
  const handlePaymentMethodRetrieved = (status) => {
    setHasPaymentMethod(status);
  };
  const isMobile = windowSize.width <= 768; 

  return (
    <div> {isMobile ? (
      <OnboardingStepsMobile currentStep={4} />
    ) : (
      <OnboardingSteps currentStep={4} />
    )}

    <div>
      <SavePaymentMethod onPaymentMethodRetrieved={handlePaymentMethodRetrieved} />
      {hasPaymentMethod && (
        <button
          className="next-step-button"
          onClick={() => navigate("/shop-subscriptions")}
          style={{
            marginTop: "20px",
            padding: "10px 20px",
            backgroundColor: "#4caf50",
            color: "white",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
          }}
        >
          Next Step
        </button>
      )}
    </div>
    </div>
  );
};

export default ShopPaymentMethodPage;
