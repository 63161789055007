import React, { useState, useEffect } from "react";
import axios from "axios";
import { server } from "../../server";
import Loader from "../Layout/Loader";
import { AiOutlineCamera } from "react-icons/ai";
import { loadSeller } from "../../redux/actions/user";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import ImageCropperModal from "../Sponsored/ImageCropperModal";

const ShopAvatar = ({ isOwner, shop, setShop }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [shopAvatar, setShopAvatar] = useState(null);
  const [showAvatarCropper, setShowAvatarCropper] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [croppedFile, setCroppedFile] = useState(null);
  const [showSaveButton, setShowSaveButton] = useState(false);
  const currentProfile = sessionStorage.getItem("currentProfile");
  const dispatch = useDispatch();

  useEffect(() => {
    setShopAvatar(shop.avatar?.url || null);
  }, [shop]);

  const handleAvatarChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      setShowAvatarCropper(true);
    }
  };

  const updateAvatar = (croppedFile) => {
    setCroppedFile(croppedFile);
    const previewUrl = URL.createObjectURL(croppedFile);
    setShopAvatar(previewUrl);
    setShowSaveButton(true);
  };

  const handleSaveAvatar = async () => {
    try {
      const formData = new FormData();
      formData.append("avatar", croppedFile);

      await axios.put(
        `${server}/shop/update-shop-avatar`,
        formData,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "multipart/form-data",
            "Current-Profile": currentProfile,
          },
        }
      );
      dispatch(loadSeller());
      toast.success("Avatar updated successfully!");
      setShowSaveButton(false);
      setShop({ ...shop, avatar: { url: shopAvatar } });
    } catch (error) {
      toast.error(error.response?.data?.message || "Failed to update avatar");
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="shop-avatar-wrapper">
      <img
        src={shopAvatar || 'default-shop-avatar.png'}
        alt={shop.name}
        className="shop-info-sidebar-avatar"
      />
      {isOwner && (
        <div className="shop-info-sidebar-avatar-edit">
          <input
            type="file"
            id="avatar"
            className="hidden"
            onChange={handleAvatarChange}
          />
          <label htmlFor="avatar">
            <AiOutlineCamera className="shop-info-sidebar-avatar-label"/>
          </label>
          {showSaveButton && (
            <button
              className="shop-avatar-save-button"
              onClick={handleSaveAvatar}
              disabled={!croppedFile}
            >
              Save
            </button>
          )}
        </div>
      )}

      {showAvatarCropper && (
        <ImageCropperModal
          updateAvatar={updateAvatar}
          closeModal={() => setShowAvatarCropper(false)}
          initialImage={selectedFile}
          aspectRatio={1}
          minWidth={200}
          isAvatar={true}
        />
      )}
    </div>
  );
};

export default ShopAvatar;
