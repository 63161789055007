import React from "react";
import { AiOutlineFolderAdd, AiOutlineGift } from "react-icons/ai";
import { FiPackage, FiShoppingBag, FiCreditCard } from "react-icons/fi";
import { MdOutlineLocalOffer, MdOutlineEventNote, MdOutlineSubscriptions, MdOutlineAdsClick } from "react-icons/md";
import { RxDashboard } from "react-icons/rx";
import { VscNewFile } from "react-icons/vsc";
import { CiMoneyBill, CiSettings, CiDeliveryTruck } from "react-icons/ci";
import { Link } from "react-router-dom";
import { BiMessageSquareDetail } from "react-icons/bi";
import { HiOutlineReceiptRefund } from "react-icons/hi";
import { useSelector } from "react-redux";
import ProfileSwitchButtonShop from "../ShopSwitch";

const DashboardSideBar = ({ active }) => {
  const { seller } = useSelector((state) => state.seller);
  console.log('Current seller state:', seller);

  const menuItems = [
    { id: 1, path: `/dashboard/${seller?.handle}`, icon: RxDashboard, label: "Dashboard" },
    { id: 2, path: "/dashboard-orders", icon: FiShoppingBag, label: "All Orders" },
    { id: 3, path: "/dashboard-products", icon: FiPackage, label: "All Products" },
    { id: 4, path: "/dashboard-create-product", icon: AiOutlineFolderAdd, label: "Create Product" },
    { id: 5, path: "/dashboard-events", icon: MdOutlineEventNote, label: "All Sales Events" },
    { id: 6, path: "/dashboard-create-event", icon: MdOutlineEventNote, label: "Create Sale Event" },
    { id: 7, path: "/dashboard-create-ad", icon: MdOutlineAdsClick, label: "Ad Center" },
    { id: 8, path: "/dashboard-withdraw-money", icon: CiMoneyBill, label: "Withdraw Money" },
    { id: 9, path: "/dashboard-messages", icon: BiMessageSquareDetail, label: "Shop Inbox" },
    { id: 10, path: "/dashboard-coupons", icon: AiOutlineGift, label: "Discount Codes" },
    { id: 11, path: "/dashboard-refunds", icon: HiOutlineReceiptRefund, label: "Refunds" },
    { id: 12, path: "/settings", icon: CiSettings, label: "Settings" },
    { id: 13, path: "/dashboard-create-brand", icon: AiOutlineFolderAdd, label: "Brand Manager" },
    { id: 14, path: "/dashboard-shipping", icon: CiDeliveryTruck, label: "Shipping" },
    { id: 15, path: "/dashboard-subscription", icon: MdOutlineSubscriptions, label: "Subscription" },
    { id: 16, path: "/dashboard-purchases", icon: FiShoppingBag, label: "All Purchases" },
    { id: 17, path: "/dashboard-payments", icon: FiCreditCard, label: "Payments Dashboard" },
    { id: 18, path: "/dashboard-payment-setup", icon: FiCreditCard, label: "Account set-up" },
  ];

  return (
    <div className="shop-dashboard-sidebar-container">
      {menuItems.map((item) => (
        <div className="shop-dashboard-sidebar-item" key={item.id}>
          <Link to={item.path} className="shop-dashboard-sidebar-link">
            <item.icon className="icon" color={`${active === item.id ? "crimson" : "#555"}`} />
            <h5 className={active === item.id ? "shop-dashboard-sidebar-link active" : "shop-dashboard-sidebar-link inactive"}>
              {item.label}
            </h5>
          </Link>
        </div>
      ))}
      <div className="shop-dashboard-sidebar-profile-switch">
        <ProfileSwitchButtonShop />
      </div>
    </div>
  );
};

export default DashboardSideBar;
