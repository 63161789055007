import React from 'react';

const BentoCTA = () => {
  return (
    <div>
      {/* Title */}
      <h1 className="bento-title">BuzzVibe Showcase</h1>
      
      <div className="bento-container">
        <div className="bento-column bento-column-left">
          {/* Left Column Content */}
          Left Column
        </div>

        <div className="bento-middle">
          <div className="bento-box bento-box-top">
            {/* Top Middle Box Content */}
            Top Middle Box
          </div>
          <div className="bento-box bento-box-bottom">
            {/* Bottom Middle Box Content */}
            Bottom Middle Box
          </div>
        </div>

        <div className="bento-column bento-column-right">
          {/* Right Column Content */}
          Right Column
        </div>
      </div>
    </div>
  );
};

export default BentoCTA;

  