import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { fetchConnections } from '../../redux/actions/connections';

const FollowingDisplay = React.memo(({ userId, profileType }) => {
  const maxDisplay = 5;
  const dispatch = useDispatch();

  // Select followers, isLoading, error, and hasFetchedFollowers from the Redux state
  const { following, isLoading, error, hasFetchedFollowing } = useSelector(state => state.connections);

  // Fetch followers only if they haven't been fetched yet
  useEffect(() => {
    if (userId && profileType && !hasFetchedFollowing) {
      dispatch(fetchConnections(userId, profileType, 'following'));
    }
  }, [userId, profileType, dispatch, hasFetchedFollowing]);

  // Memoize the followers display to prevent unnecessary re-renders
  const displayedFollowing = useMemo(() => {
    return following ? following.slice(0, maxDisplay) : [];
  }, [following]);

  return (
    <div className="followers-avatar-display-container">
      {isLoading && <div>Loading followers...</div>}
      {error && <div>Error: {error}</div>}
      {!isLoading && hasFetchedFollowing && displayedFollowing.length > 0 ? (
        <>
          <div className="followers-avatar-display-images">
            {displayedFollowing.map((following, index) => (
              following && following._id ? (
                <img
                  key={index}
                  className="followers-avatar-display-image"
                  src={following.avatar}
                  alt={following.name}
                />
              ) : (
                <div key={index} className="follower-placeholder">No Data</div>
              )
            ))}
          </div>
          <div className="followers-avatar-display-count">
            <span>{following.length} Following</span>
          </div>
        </>
      ) : (
        !isLoading && hasFetchedFollowing && <div>No followers to display.</div>
      )}
    </div>
  );
});

export default FollowingDisplay;