import { Close } from "@mui/icons-material";
import ImageCropper from "./ImageCropper";
import AvatarImageCropper from "./AvatarImageCropper";

const ImageCropperModal = ({ updateAvatar, closeModal, initialImage, aspectRatio, minWidth, previousCrop, isAvatar }) => {
    const MIN_HEIGHT = minWidth / aspectRatio;

    return (
        <div className="image-cropper-modal">
            <div className="image-cropper-modal__overlay"></div>
            <div className="image-cropper-modal__content-wrapper">
                <div className="image-cropper-modal__content">
                    <div className="image-cropper-modal__header">
                        <button
                            type="button"
                            className="image-cropper-modal__close-button"
                            onClick={closeModal}
                        >
                            <span className="sr-only">Close menu</span>
                            <Close />
                        </button>
                        
                        {isAvatar ? (
                            <AvatarImageCropper
                                updateAvatar={updateAvatar}
                                closeModal={closeModal}
                                initialImage={initialImage} 
                                minWidth={minWidth}     
                                previousCrop={previousCrop}     
                            />
                        ) : (
                            <ImageCropper
                                updateAvatar={updateAvatar}
                                closeModal={closeModal}
                                initialImage={initialImage} 
                                aspectRatio={aspectRatio}    
                                minWidth={minWidth}     
                                minHeight={MIN_HEIGHT}
                                previousCrop={previousCrop}     
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ImageCropperModal;



