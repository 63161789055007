import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineDelete, AiOutlineEdit, AiOutlineEye, AiOutlineClose, AiOutlinePlus, AiOutlineUnorderedList } from "react-icons/ai";
import { Link } from "react-router-dom";
import Loader from "../Layout/Loader";
import EditProduct from "../Products/EditProducts";
import axios from 'axios';
import { getAllProductsShop, deleteProduct } from "../../redux/actions/product";
import { server } from "../../server";
import AddInventory from "./AddInventory";
import InventoryLog from "./InventoryLog";


const AllProducts = () => {
  const { products, isLoading } = useSelector((state) => state.products);
  const { seller } = useSelector((state) => state.seller);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteProductId, setDeleteProductId] = useState(null);
  const [addInventoryModalOpen, setAddInventoryModalOpen] = useState(false);
  const [inventoryLogModalOpen, setInventoryLogModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const dispatch = useDispatch();
  

  useEffect(() => {
    if (seller._id) {
      dispatch(getAllProductsShop(seller._id));
    }
  }, [dispatch, seller._id]);

  const openDeleteModal = (id) => {
    setDeleteProductId(id);
    setDeleteModalOpen(true);
  };

  const handleDelete = async () => {
    try {
      await axios.delete(`${server}/product/delete-shop-product/${deleteProductId}`, {
        withCredentials: true,
        headers: {
          'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
        },
      });
      dispatch(getAllProductsShop(seller._id));
      setDeleteModalOpen(false);
    } catch (error) {
      setErrorMessage("Failed to delete product. Please try again later.");
      console.error("Error deleting product:", error.response.data);
    }
  };

  const handleEdit = (productId) => {
    setSelectedProductId(productId);
    setEditModalOpen(true);
  };
  const handleAddInventory = (productId) => {
    setSelectedProductId(productId);
    setAddInventoryModalOpen(true);
  };
  const handleViewInventoryLog = (productId) => {
    setSelectedProductId(productId);
    setInventoryLogModalOpen(true);
  };

  const onInventoryAdded = () => {
    dispatch(getAllProductsShop(seller._id));
  };

  const rows = products.map((product) => ({
    id: product._id,
    name: product.name,
    productType: Array.isArray(product.productType) ? product.productType.join(', ') : product.productType,
    price: `US$ ${product.discountPrice.toFixed(2)}`,
    Stock: product.stock,
    sold: product.sold_out,
    image: product.images[0]?.url || 'defaultProductImageUrl',
    SKU: product.SKU,
    category: product.category,
  }));

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="all-products-shop-component">
          <div className="header">
            <h1>All Products</h1>
          </div>
          <div className="all-products-shop-component-data-grid-container">
            <table>
              <thead className="all-products-shop-component-header">
                <tr>
                  <th>Image</th>
                  <th>Product ID</th>
                  <th>Name</th>
                  <th>Product Type</th>
                  <th>Price</th>
                  <th>Stock</th>
                  <th>SKU</th>
                  <th>Category</th>
                  <th>Sold Out</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {rows.map((row) => (
                  <tr key={row.id}>
                    <td><img src={row.image} alt={row.name} /></td>
                    <td>{row.id}</td>
                    <td>{row.name}</td>
                    <td>{row.productType}</td>
                    <td>{row.price}</td>
                    <td>{row.Stock}</td>
                    <td>{row.SKU}</td>
                    <td>{row.category}</td>
                    <td>{row.sold}</td>
                    <td className="actions">
                      <Link to={`/product/${row.id}`} style={{ marginRight: '2px' }}>
                        <AiOutlineEye size={20} title="Preview" />
                      </Link>
                      <button onClick={() => handleEdit(row.id)} style={{ color: '#4B5563', marginRight: '2px' }}>
                        <AiOutlineEdit size={20} title="Edit" />
                      </button>
                      <button onClick={() => openDeleteModal(row.id)} style={{ color: '#EF4444' }}>
                        <AiOutlineDelete size={20} title="Delete" />
                      </button>
                      <button onClick={() => handleAddInventory(row.id)} style={{ color: '#4CAF50' }}>
                        <AiOutlinePlus size={20} title="Add Inventory" />
                      </button>
                      <button onClick={() => handleViewInventoryLog(row.id)} style={{ color: '#FFC107' }}>
                        <AiOutlineUnorderedList size={20} title="View Inventory Log" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {editModalOpen && (
        <div className="modal-style">
          <AiOutlineClose className="close-icon-style" onClick={() => setEditModalOpen(false)} />
          {selectedProductId && <EditProduct productId={selectedProductId} onClose={() => setEditModalOpen(false)} />}
        </div>
      )}
      {deleteModalOpen && (
        <div className="delete-modal-style">
          <h2>Are you sure you want to delete this product?</h2>
          <button onClick={handleDelete} className="delete-button">Yes</button>
          <button onClick={() => setDeleteModalOpen(false)} className="cancel-button">No</button>
        </div>
      )}
      {addInventoryModalOpen && (
        <div className="modal-style">
          <AiOutlineClose className="close-icon-style" onClick={() => setAddInventoryModalOpen(false)} />
          {selectedProductId && <AddInventory productId={selectedProductId} onClose={() => setAddInventoryModalOpen(false)} onInventoryAdded={onInventoryAdded} />}
        </div>
      )}
      {inventoryLogModalOpen && (
        <div className="modal-style">
          <AiOutlineClose className="close-icon-style" onClick={() => setInventoryLogModalOpen(false)} />
          {selectedProductId && <InventoryLog productId={selectedProductId} />}
        </div>
      )}
    </>
  );
};

export default AllProducts;
