import React, { useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { toggleFriendListPanel, toggleNotificationSidebar, toggleWalletSidebar } from '../../state/uiSlice'; 
import { loadNotifications, resetUnseenNotifications } from '../../redux/actions/notifications'; 
import {
  CalendarIcon,
  ChartBarIcon,
  HomeIcon,
  BellIcon,
  BuildingStorefrontIcon,
  UsersIcon,
  WalletIcon,
  MapIcon
} from '@heroicons/react/24/outline';
import FriendListSlidingPanel from '../FriendsListPanel';
import ProfileSwitchButton from '../ProfileSwitchButton';
import NotificationSidebar from '../NotificationSidebar';
import FollowersFollowingPanel from '../Followers/FollowersFollowingPanel';
import CryptoWalletSidebar from '../../widgets/CryptoWallet';
import ConnectionsPanel from '../Connections/ConnectionsPanel';
import BannerAd from '../Sponsored/BannerAd';
import TopBannerAd from '../Sponsored/TopBannerAd';
import MobileBarBannerAd from '../Sponsored/MobileBannerAd';


// Bell Icon with Notification Badge
const BellWithNotifications = ({ unseenCount }) => (
  <div className="sidebar-relative">
    <BellIcon className="sidebar-icon" aria-hidden="true" />
    {unseenCount > 0 && (
      <span className="sidebar-badge">
        {unseenCount}
      </span>
    )}
  </div>
);

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Sidebar() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { loading, user, currentProfile } = useSelector((state) => state.user);
  const { isFriendListPanelOpen, isNotificationSidebarOpen, isWalletSidebarOpen } = useSelector((state) => state.ui);
  const { unseenCount } = useSelector((state) => state.notifications);
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { seller } = useSelector((state) => state.seller);
  const userId = user?._id;
  const sellerId = seller?._id;
  const profileId = currentProfile === 'User' ? userId : sellerId;

  useEffect(() => {
    if (profileId) {
      dispatch(loadNotifications(currentProfile, profileId));
    }
  }, [dispatch, currentProfile, profileId]);

  const handleNavigationClick = (item) => {
    console.log('Clicked:', item);
    if (item.onClick === 'openTeam') {
      dispatch(toggleFriendListPanel());
      console.log('Toggling Friends List Panel');
    }
    if (item.onClick === 'openNotifications') {
      dispatch(toggleNotificationSidebar());
      dispatch(resetUnseenNotifications(profileId, currentProfile));
      console.log('Toggling notifications Panel');
    }
    if (item.onClick === 'openWalletSidebar') {
      dispatch(toggleWalletSidebar());  
    } setMobileMenuOpen(false);
    console.log('Toggling wallet Panel');
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const shopLink = currentProfile === 'User' ? '/marketplace' : '/wholesale/products';

  // Define your navigation array with a unique id for each item
  let navigation = [
    { id: 'home', name: 'Home', icon: HomeIcon, href: '/home', current: location.pathname === '/home' },
    { id: 'notifications', name: 'Notifications', icon: () => <BellWithNotifications unseenCount={unseenCount} />, href: '#', current: false, onClick: 'openNotifications' },
    { id: 'team', name: 'Team', icon: UsersIcon, href: '#', current: false, onClick: 'openTeam' },
    { id: 'shop', name: 'Shop', icon: BuildingStorefrontIcon, href: shopLink, current: location.pathname === shopLink },
    { id: 'map', name: 'Map', icon: MapIcon, href: '/map', current: location.pathname === '/map' },
    { id: 'events', name: 'Events', icon: CalendarIcon, href: '/events', current: location.pathname === '/events' },
    { id: 'custom', name: 'Custom Sidebar', icon: WalletIcon, href: '#', current: false, onClick: 'openWalletSidebar' },
  ];

  if (currentProfile !== 'User') {
    navigation.push({ id: 'dashboard', name: 'Dashboard', icon: ChartBarIcon, href: '/shop-login', current: location.pathname === '/shop-login' });
  }

  return (
    <>
      {/* Mobile Button */}
      <div className="mobile-sidebar">
      <div className="mobile-button-container">
          <button
            className="mobile-sidebar-button"
            onClick={toggleMobileMenu}
          >
            <img 
              src="https://res.cloudinary.com/dzlopmfj8/image/upload/v1724854900/memes/evajdtncfvfdnrihmx6t.png" 
              alt="Menu Icon" 
              className="mobile-sidebar-icon" 
            />
          </button>
          <MobileBarBannerAd />
          <ProfileSwitchButton className="sidebar-profile-toolbar-avatar" />
        </div>



        {/* Modal displaying the icons */}
        {isMobileMenuOpen && (
          <>
            <div className="mobile-background-overlay"></div>
            <div className="mobile-sidebar-modal">
              <nav className="mobile-sidebar-nav">
                {navigation.map((item) => (
                  <a
                    key={item.id}
                    href={item.href}
                    className={classNames(
                      item.current ? 'sidebar-text-current' : 'sidebar-text-default',
                      'mobile-sidebar-item'
                    )}
                    onClick={() => handleNavigationClick(item)}
                  >
                    {typeof item.icon === 'function' ? <item.icon /> : <item.icon className="mobile-sidebar-icon" aria-hidden="true" />}
                    <span className="sr-only">{item.name}</span>
                  </a>
                ))}
              </nav>
            </div>
          </>
        )}
      </div>

      {/* Sidebar for Larger Screens */}
      <div id="desktop-sidebar" className="sidebar-desktop hidden lg:flex">
        <div className="sidebar-container">
          <div className="sidebar-content">
            <ProfileSwitchButton className="sidebar-profile-toolbar-avatar" />
            <nav className="sidebar-nav-large" aria-label="Sidebar">
              {navigation.map((item) => (
                <a
                  key={item.id}
                  href={item.href}
                  className={classNames(
                    item.current
                      ? 'sidebar-nav-item-current'
                      : 'sidebar-nav-item-default',
                    'sidebar-nav-item-large'
                  )}
                  onClick={() => handleNavigationClick(item)}
                >
                  {typeof item.icon === 'function' ? <item.icon /> : <item.icon
                    className={classNames(
                      item.current ? 'sidebar-icon-current' : 'sidebar-icon-default',
                      'sidebar-icon-large'
                    )}
                    aria-hidden="true"
                  />}
                  
                </a>
              ))}
            </nav>
          </div>
        </div>
      </div>

      {/* Conditional rendering of the FriendListSlidingPanel */}
      {isFriendListPanelOpen && <ConnectionsPanel />}
      {isNotificationSidebarOpen && <NotificationSidebar />}
      {isWalletSidebarOpen && <CryptoWalletSidebar />}
    </>
  );
}

