import React, { useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  AgeVerificationPage,
  LoginPage,
  SignupPage,
  ActivationPage,
  HomePage,
  ProductsPage,
  CategoryProductsPage,
  BestSellingPage,
  EventsPage,
  FAQPage,
  CheckoutPage,
  PaymentPage,
  OrderSuccessPage,
  ProductDetailsPage,
  ProfilePage,
  ShopCreatePage,
  SellerActivationPage,
  ShopLoginPage,
  OrderDetailsPage,
  TrackOrderPage,
  UserInbox,
  LandingPage,
  WholesaleProductsPage,
  LandingPageTest
} from "./routes/Routes.js";
import {
  ShopDashboardPage,
  ShopCreateProduct,
  ShopAllProducts,
  ShopCreateEvents,
  ShopAllEvents,
  ShopAllCoupons,
  ShopPreviewPage,
  ShopAllOrders,
  ShopOrderDetails,
  ShopAllRefunds,
  ShopSettingsPage,
  ShopWithDrawMoneyPage,
  ShopInboxPage,
  ShopCreateAdvertisement,
  ShopHomePage,
  BrandPage,
  ShopAllPurchases,
} from "./routes/ShopRoutes";
import {
  AdminDashboardPage,
  AdminDashboardUsers,
  AdminDashboardSellers,
  AdminDashboardOrders,
  AdminDashboardProducts,
  AdminDashboardEvents,
  AdminDashboardWithdraw
} from "./routes/AdminRoutes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Store from "./redux/store";
import { loadSeller, loadUser } from "./redux/actions/user";
import ProtectedRoute from "./routes/ProtectedRoute";
import ProtectedAdminRoute from "./routes/ProtectedAdminRoute";
import SellerProtectedRoute from "./routes/SellerProtectedRoute";
import { getAllProducts } from "./redux/actions/product";
import { getAllEvents } from "./redux/actions/event";
import axios from "axios";
import { server } from "./server";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useNavigate } from "react-router-dom";
import AgeVerificationWrapper from './components/AgeVerificationWrapper';
import PublicProfilePage from "./pages/PublicProfilePage";
import CookieConsent from "./components/CookieConsent";
import { useCookies } from 'react-cookie';
import PrivacyPolicy from "./pages/PrivacyPolicy";
import PrivateProfilePage from "./pages/PrivatePublicProfilePage";
import MapPage from "./pages/Map";
import SponsoredPostsAdmin from "./components/Admin/SponsoredPostsAdmin";
import AdminDashboardAds from "./pages/AdminDashboardAds";
import LoginPageOauth from "./pages/LoginOauth";
import SignupPageOauth from "./pages/SignupPageOauth";
import ActivationPageOauth from "./pages/ActivationPageOauth.jsx";
import OwnedBrand from "./components/Brands/OwnedBrand.jsx";
import BrandManagementPage from "./pages/BrandManagementPage.jsx";
import ForgotPasswordPage from "./pages/ForgotPasswordPage.jsx";
import ForgotPasswordResetPassword from "./components/Login/ForgotPasswordReset.jsx";
import SalesEventsPage from "./pages/SalesEventsPage.jsx";
import EventDetailsPage from "./pages/EventDetailsPage.jsx";
import ShopByBrandPage from "./pages/ShopByBrandPage.jsx";
import ShopShipping from "./pages/Shop/ShopShipping.jsx";
import SubscriptionPage from "./pages/SubscriptionPage.jsx";
import SubscriptionCheckoutPage from "./pages/SubscriptionCheckoutPage.jsx";
import SubscriptionProtectedRoute from "./routes/SubscriptionProtectedRoute.js";
import ShopSubscriptionPage from "./pages/Shop/ShopSubscriptionPage.jsx";
import ShopSubscriptionCheckoutPage from "./pages/Shop/ShopSubscriptionCheckoutPage.jsx";
import ShopSubscriptionsOverview from "./pages/Shop/ShopSubscriptions.jsx";
import ShopProtectedRoute from "./routes/ShopSubscriptionProtectedRoute.js";
import ShopPaymentSettingsPage from "./pages/Shop/ShopPaymentSettingsPage.jsx";
import TempPage from "./pages/temppage.jsx";
import AdminTagManager from "./pages/AdminTagManager.jsx";
import StripePaymentDashboard from "./components/Payment/StripePaymentDashboard.jsx";
import OrderSuccess from "./components/Orders/OrderConfirmation.jsx";
import SellerOrderDetails from "./components/SellerOrderDetails.jsx";
import HashtagPosts from "./components/Hashtags/HashtagPosts.jsx";
import MembershipProcess from "./components/Membership/MembershipProcess.jsx";
import AdminQrScanner from "./components/Membership/AdminQrScanner.jsx";
import MembershipSuccess from "./components/Membership/MembershipSuccess.jsx";
import StudioAdminDashboard from "./components/Membership/StudioAdminDashboard.jsx";
import ReportedPosts from "./components/Compliance/ReportedPosts.jsx";
import EventsPageContainer from "./pages/EventsPageContainer.jsx";
import CasinoLandingPage from "./pages/GamblingPage.jsx";
import RoomDetailsPage from "./pages/RoomDetailsPage.jsx";
import MarketplacePage from "./pages/MarketplacePage.jsx";
import AdminDashboardSubscriptions from "./pages/AdminDashboardSubscriptions.jsx";
import ShopPaymentMethodPage from "./pages/Shop/ShopPaymentMethodPage.jsx";
import PageNotFound from "./pages/PageNotFound.jsx";




const App = () => {

  const [stripeApikey, setStripeApiKey] = useState("");
  const [cookies] = useCookies(["cookieConsent"]);

  async function getStripeApikey() {
    const { data } = await axios.get(`${server}/payment/stripeapikey`);
    setStripeApiKey(data.stripeApikey);
  }
  useEffect(() => {
    Store.dispatch(loadUser());
    Store.dispatch(loadSeller());
    Store.dispatch(getAllProducts());
    Store.dispatch(getAllEvents());
    getStripeApikey();
  }, []);


  

  return (
    <BrowserRouter>
      {stripeApikey && (
        <Elements stripe={loadStripe(stripeApikey)}>
          <Routes>
            <Route
              path="/payment"
              element={
                <ProtectedRoute>
                  <PaymentPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dashboard-payment-setup"
              element={
                  <ShopPaymentSettingsPage />
              }
            />
            <Route
              path="/onboarding"
              element={
                  <ShopPaymentSettingsPage />
              }
            />
            <Route
              path="/payment-method-setup"
              element={
                  <ShopPaymentMethodPage />
              }
            />
            <Route
              path="/dashboard-payments"
              element={
                  <StripePaymentDashboard />
              }
            />
            <Route
              path="/subscription-checkout/:priceId"
              element={
                <ProtectedRoute>
                  <SubscriptionCheckoutPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/shop-subscription-checkout/:priceId"
              element={
                <ProtectedRoute>
                  <ShopSubscriptionCheckoutPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/membership-sign-up"
              element={
                
                  <MembershipProcess />
                
              }
            />
          </Routes>
        </Elements>
      )}
      {!cookies.cookieConsent && <CookieConsent />}
      <Routes>
      <Route path="/admin-studio-qr-scanner" element={<AdminQrScanner />} />
      <Route path="/admin-studio-dashboard" element={<StudioAdminDashboard />} />
      <Route path="/membership-success" element={<MembershipSuccess />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/ageverificationpage" element={<AgeVerificationPage />} />
        
        <Route
          path="/activation/:activation_token"
          element={<ActivationPage />}
        />
        <Route
          path="/seller/activation/:activation_token"
          element={<SellerActivationPage />}
        />
       
            
              
        <Route path="/homepage" element={<HomePage />} />
        <Route path="/" element={<TempPage />} />
        <Route path="/home" element={<LandingPageTest />} />
        <Route path="/test" element={<LandingPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/login/oauth" element={<LoginPageOauth />} />
        <Route path="/forgot-password" element={<ForgotPasswordPage />} />
        <Route path="/reset-password/:token" element={<ForgotPasswordResetPassword />} />
        <Route path="/signup" element={<SignupPage />} />
        <Route path="/sign-up/oauth" element={<SignupPageOauth />} />
        <Route path="/activation" element={<ActivationPageOauth />} />
        <Route path="/products" element={<ProductsPage />} />
        <Route path="/marketplace" element={<MarketplacePage />} />
        <Route path="/wholesale/products" element={<WholesaleProductsPage />} />
        <Route path="/products/:category" element={<CategoryProductsPage />} />
        <Route path="/product/:id" element={<ProductDetailsPage />} />
        <Route path="/best-selling" element={<BestSellingPage />} />
        <Route path="/sales" element={<SalesEventsPage />} />
        <Route path="/events" element={  <EventsPageContainer /> } />
        <Route path="/events-sub" element={ <SubscriptionProtectedRoute requiredTiers={['Super OG']}> <EventsPage /> </SubscriptionProtectedRoute>} />
        <Route path="/brand/:brandName" element={<ShopByBrandPage />} />
        <Route path="/events/:eventId" element={<EventDetailsPage />} />
        <Route path="/subscriptions" element={<SubscriptionPage />} />
        <Route path="/shop-subscriptions" element={<ShopSubscriptionPage/>} />
        <Route path="/hashtag/:hashtag" element={<HashtagPosts/>} />
        <Route path="/faq" element={<FAQPage />} />
        <Route path="/map" element={<MapPage />} />
        <Route
          path="/checkout"
          element={
            <ProtectedRoute>
              <CheckoutPage />
            </ProtectedRoute>
          }
        />
        <Route path="/order/" element={<OrderSuccess />} />
        <Route path="/casino-demo" element={<CasinoLandingPage />} />
        <Route path= "/profile/:id" element={<PublicProfilePage />}/>
        <Route path= "/my-profile/:id" element={<PrivateProfilePage />}/>
        <Route path="/rooms/:handle" element={<RoomDetailsPage />} />

        <Route
          path="/profile"
          element={
            <ProtectedRoute>
              <ProfilePage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/inbox"
          element={
            <ProtectedRoute>
              <UserInbox />
            </ProtectedRoute>
          }
        />
        <Route
          path="/user/order/:id"
          element={
            <ProtectedRoute>
              <OrderDetailsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/user/track/order/:id"
          element={
            <ProtectedRoute>
              <TrackOrderPage />
            </ProtectedRoute>
          }
        />
        <Route path="/shop/:handle" element={<ShopPreviewPage />} />
        {/* shop Routes */}
        <Route path="/shop-create" element={<ShopCreatePage />} />
        <Route path="/shop-login" element={<ShopLoginPage />} />
        <Route
          path="/shop/preview/:handle"
          element={
            
              <ShopHomePage />
            
          }
        />
        <Route
          path="/settings"
          element={
            <SellerProtectedRoute>
              <ShopSettingsPage />
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/dashboard/:handle"
          element={
            <SellerProtectedRoute>
              <ShopDashboardPage />
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/dashboard-create-product"
          element={
            <SellerProtectedRoute>
              <ShopCreateProduct />
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/dashboard-create-brand"
          element={
            <SellerProtectedRoute>
              <BrandPage />
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/dashboard/brands/:brandId"
          element={
            
            <BrandManagementPage />
            
          }
        />
        <Route
          path="/dashboard-shipping"
          element={
            <SellerProtectedRoute>
            <ShopShipping />
            </SellerProtectedRoute>
          }
        />
        
        <Route
          path="/dashboard-orders"
          element={
            <SellerProtectedRoute>
              <ShopAllOrders />
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/dashboard-purchases"
          element={
            <SellerProtectedRoute>
              <ShopAllPurchases />
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/dashboard-refunds"
          element={
            <SellerProtectedRoute>
              <ShopAllRefunds />
            </SellerProtectedRoute>
          }
        />

        <Route
          path="/order/:id"
          element={
            <SellerProtectedRoute>
              <ShopOrderDetails />
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/purchase/:id"
          element={
            <SellerProtectedRoute>
              <SellerOrderDetails />
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/dashboard-products"
          element={
            <SellerProtectedRoute>
              <ShopAllProducts />
            </SellerProtectedRoute>
          }
        />
       <Route
          path="/dashboard-create-event"
          element={
            <SellerProtectedRoute>
            
              <ShopCreateEvents />
            
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/dashboard-create-event-subscription"
          element={
            <SellerProtectedRoute>
            <ShopProtectedRoute requiredTiers={['Super OG Shop']}>
              <ShopCreateEvents />
            </ShopProtectedRoute>
            </SellerProtectedRoute>
          }
        />

        <Route
          path="/dashboard-create-ad"
          element={
            <SellerProtectedRoute>
              <ShopCreateAdvertisement />
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/dashboard-events"
          element={
            <SellerProtectedRoute>
              <ShopAllEvents />
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/dashboard-coupons"
          element={
            <SellerProtectedRoute>
              <ShopAllCoupons />
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/dashboard-withdraw-money"
          element={
            <SellerProtectedRoute>
              <ShopWithDrawMoneyPage />
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/dashboard-messages"
          element={
            <SellerProtectedRoute>
              <ShopInboxPage />
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/dashboard-subscription"
          element={
            <SellerProtectedRoute>
              <ShopSubscriptionsOverview />
            </SellerProtectedRoute>
          }
        />

        
        {/* Admin Routes */}
        <Route
          path="/admin/dashboard"
          element={
            <ProtectedAdminRoute>
              <AdminDashboardPage />
            </ProtectedAdminRoute>
          }
        />
        <Route
          path="/admin-users"
          element={
            <ProtectedAdminRoute>
              <AdminDashboardUsers />
            </ProtectedAdminRoute>
          }
        />
        <Route
          path="/admin-sellers"
          element={
            <ProtectedAdminRoute>
              <AdminDashboardSellers />
            </ProtectedAdminRoute>
          }
        />
        <Route
          path="/admin-orders"
          element={
            <ProtectedAdminRoute>
              <AdminDashboardOrders />
            </ProtectedAdminRoute>
          }
        />
         <Route
          path="/admin-products"
          element={
            <ProtectedAdminRoute>
              <AdminDashboardProducts />
            </ProtectedAdminRoute>
          }
        />
         <Route
          path="/admin-events"
          element={
            <ProtectedAdminRoute>
              <AdminDashboardEvents />
            </ProtectedAdminRoute>
          }
        />
        <Route
          path="/admin-ads"
          element={
            <ProtectedAdminRoute>
              <AdminDashboardAds/>
            </ProtectedAdminRoute>
          }
        />
        <Route
          path="/admin-tags"
          element={
            <ProtectedAdminRoute>
              <AdminTagManager/>
            </ProtectedAdminRoute>
          }
        />
        <Route
          path="/admin-reports"
          element={
            <ProtectedAdminRoute>
              <ReportedPosts/>
            </ProtectedAdminRoute>
          }
        />
        <Route
          path="/admin-subscriptions"
          element={
            <ProtectedAdminRoute>
              <AdminDashboardSubscriptions/>
            </ProtectedAdminRoute>
          }
        />
         <Route
          path="/admin-withdraw-request"
          element={
            <ProtectedAdminRoute>
              <AdminDashboardWithdraw />
            </ProtectedAdminRoute>
          }
        />
        
            
          
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </BrowserRouter>
  );
};

export default App;
