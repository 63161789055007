import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

const DualProductAdCarouselProps = ({ ads }) => { 
    const [currentIndex, setCurrentIndex] = useState(0);
    const adRefs = useRef(new Map());

    // Group ads into pairs
    const groupAdsInPairs = (ads) => {
        const pairs = [];
        for (let i = 0; i < ads.length; i += 2) {
            pairs.push(ads.slice(i, i + 2)); // Group ads in pairs of 2
        }
        return pairs;
    };

    const adPairs = groupAdsInPairs(ads); // Group ads into pairs

    const logViewedImpression = async (adId) => {
        try {
            await axios.post(`/sponsored/viewed-impression/${adId}`, {}, {
                withCredentials: true,
                headers: {
                    'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
                }
            });
            console.log(`Viewed impression logged for ad: ${adId}`);
        } catch (error) {
            console.error(`Error logging viewed impression for ad: ${adId}`, error);
        }
    };

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    const adId = entry.target.dataset.adId;
                    if (entry.isIntersecting) {
                        const timer = setTimeout(() => {
                            logViewedImpression(adId);
                        }, 1000);
                        entry.target._timer = timer;
                    } else {
                        clearTimeout(entry.target._timer);
                    }
                });
            },
            { threshold: 0.5 }
        );

        const currentAdPair = adPairs[currentIndex] || [];
        currentAdPair.forEach((ad) => {
            const adElement = adRefs.current.get(ad._id);
            if (adElement) {
                observer.observe(adElement);
            }
        });

        return () => {
            observer.disconnect();
        };
    }, [adPairs, currentIndex]);

    const logAdClick = async (ad_id) => {
        try {
            await axios.post(`/sponsored/click/${ad_id}`, {}, {
                withCredentials: true,
                headers: {
                    'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
                }
            });
            console.log('Click logged for ad:', ad_id);
        } catch (error) {
            console.error('Error logging click:', error);
        }
    };

    const nextSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % adPairs.length);
    };

    const prevSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + adPairs.length) % adPairs.length);
    };

    if (adPairs.length === 0) {
        return <div className="dual-product-ad-carousel-loading">Loading...</div>;
    }

    const currentAdPair = adPairs[currentIndex]; // Get the current pair of ads

    return (
        <div className="dual-product-ad-carousel-container">
            <>
                <div className="dual-product-ad-carousel-wrapper">
                    {/* Render the pair of ads */}
                    {currentAdPair.map((ad) => (
                        ad.product && ad.product._id ? (
                            <Item 
                                key={ad._id} 
                                ad={ad} 
                                logAdClick={() => logAdClick(ad._id)} 
                                adRef={el => adRefs.current.set(ad._id, el)} 
                            />
                        ) : (
                            <div key={ad._id} className="dual-product-ad-carousel-error">
                                Error: Missing product data.
                            </div>
                        )
                    ))}
                </div>
                {adPairs.length > 1 && (
                    <>
                        <button className="sponsored-carousel-nav-button sponsored-carousel-prev" onClick={prevSlide}>‹</button>
                        <button className="sponsored-carousel-nav-button sponsored-carousel-next" onClick={nextSlide}>›</button>
                    </>
                )}
            </>
        </div>
    );
};

const Item = ({ ad, logAdClick, adRef }) => {
    const { images, positions, scales  } = ad.adSet;
    const imageUrl = images && images["Dual Carousel"];
    const position = positions && positions["Dual Carousel"] || { x: 0, y: 0 };
    const scale = scales && scales["Dual Carousel"] || 1;
    console.log(`Ad ID: ${ad._id}, Position: (${position.x}, ${position.y}), Scale: ${scale}`);

    return (
        <div className="dual-product-ad-carousel-item" ref={adRef} data-ad-id={ad._id}>
            <div className="dual-product-ad-carousel-image-wrapper">
                {imageUrl && (
                    <img
                        src={imageUrl}
                        alt={ad.product.name}
                        className="dual-product-ad-carousel-image"
                        style={{
                            position: 'absolute',
                            left: `${position.x}%`,
                            top: `${position.y}%`,
                            transform: `scale(${scale})`,
                            transformOrigin: 'center',
                        }}
                    />
                )}
            </div>
            <div className="dual-product-ad-carousel-content">
                <h2 className="dual-product-ad-carousel-title">{ad.product.name}</h2>
                <p className="dual-product-ad-carousel-price">{`$${ad.product.discountPrice || ad.product.originalPrice}`}</p>
                <Link
                    to={`/product/${ad.product._id}`}
                    onClick={logAdClick}
                    className="dual-product-ad-carousel-button"
                >
                    Buy now
                </Link>
            </div>
        </div>
    );
};

export default DualProductAdCarouselProps;



