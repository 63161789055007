import { useRef, useState, useEffect } from "react";
import ReactCrop, { centerCrop, convertToPixelCrop, makeAspectCrop } from "react-image-crop";
import 'react-image-crop/dist/ReactCrop.css';
import setCanvasPreview from "./setCanvasPreview";

const AvatarImageCropper = ({ closeModal, updateAvatar, initialImage, minWidth = 150, previousCrop }) => {
  const aspectRatio = 1; // Force square aspect ratio for avatars
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [imgSrc, setImgSrc] = useState(initialImage || "");
  const [error, setError] = useState("");
  const [crop, setCrop] = useState(previousCrop || null);

  useEffect(() => {
    if (initialImage instanceof File) {
      setImgSrc(URL.createObjectURL(initialImage));
    } else {
      setImgSrc(initialImage);
    }
    if (previousCrop) {
      setCrop(previousCrop);
    }

    return () => {
      if (initialImage instanceof File) {
        URL.revokeObjectURL(imgSrc);
      }
    };
  }, [initialImage]);

  const onImageLoad = (e) => {
    const { width, height } = e.currentTarget;

    if (width <= minWidth || height <= minWidth) {
      setError(`Image must be larger than ${minWidth} x ${minWidth} pixels otherwise it will be cropped on larger screens`);
      return;
    }

    const cropWidthInPercent = (minWidth / width) * 100;

    const crop = makeAspectCrop(
      {
        unit: "%",
        width: cropWidthInPercent,
      },
      aspectRatio, // Force square aspect for avatar
      width,
      height
    );

    const centeredCrop = centerCrop(crop, width, height);
    setCrop(centeredCrop);
  };

  const handleCropImage = () => {
    if (previewCanvasRef.current && imgRef.current && crop) {
      setCanvasPreview(
        imgRef.current,
        previewCanvasRef.current,
        convertToPixelCrop(crop, imgRef.current.width, imgRef.current.height)
      );

      previewCanvasRef.current.toBlob((blob) => {
        if (blob) {
          const croppedFile = new File([blob], initialImage.name || 'cropped_avatar', { type: blob.type });
          updateAvatar(croppedFile, crop); // Pass the crop here as well
          closeModal();
        }
      });
    }
  };

  return (
    <>
      {error && 
       <div className="image-cropper__error-container">
        <p className="image-cropper__error">{error}</p>
        </div>
      }
      {imgSrc && (
        <div className="image-cropper__container">
          <ReactCrop
            crop={crop}
            onChange={(pixelCrop, percentCrop) => setCrop(percentCrop)}
            aspect={aspectRatio} // Square aspect for avatar
            minWidth={minWidth}
            circularCrop // To visually show a circular crop
          >
            <img
              ref={imgRef}
              src={imgSrc}
              crossOrigin="anonymous"
              alt="Upload"
              className="image-cropper__image"
              onLoad={onImageLoad}
              style={{ borderRadius: "50%" }} // Circular preview for avatar
            />
          </ReactCrop>

          <button
            className="image-cropper__button"
            onClick={handleCropImage}
          >
            Crop Image
          </button>
        </div>
      )}
      {crop && (
        <canvas
          ref={previewCanvasRef}
          className="image-cropper__canvas"
          style={{ display: "none" }}
        />
      )}
    </>
  );
};

export default AvatarImageCropper;
