import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { server } from '../server'; 
import UserProfileData from '../components/UserProfileData';
import Header from '../components/Layout/Header';
import Sidebar from '../components/Layout/Sidebar';
import UserBanner from '../components/Shop/UserBanner';
import MyImages from '../components/Content/MyImages';
import MyEvents from '../components/Events/MyEvents';
import MyContentCarousel from '../components/Profile/MyContentCarousel';
import RoomsProfile from '../components/Rooms/RoomsProfile';
import FollowersDisplay from '../components/Connections/FollowersDisplay';
import MyPostWidgetRedux from '../widgets/MyPostWidgetRedux';
import MixedSponsoredAds from '../components/Sponsored/MixedSponsoredAds';
import FollowingDisplay from '../components/Connections/FollowingDisplay';
import UserAvatar from '../components/Layout/UserAvatar';

const PrivateProfilePage = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const { id } = useParams(); 

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${server}/user/user-info/${id}`);
        setUser(response.data.user);
      } catch (error) {
        console.error('Error fetching user data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <div>User data not available</div>;
  }

  return (
    <div className="profile-page">
      <Header />
      <Sidebar />
      <UserBanner isUser={true} user={user} setUser={setUser} />
      <div className="profile-header"></div>
      <div className="profile-main">
        <div className="profile-info-container">
          <div className="profile-avatar-wrapper">
            <UserAvatar isUser={true} user={user} setUser={setUser} />
            <div className="profile-info">
              <div className="profile-name-wrapper">
                <h3 className="profile-name">{user.name}</h3>
                <h3 className="profile-handle">@{user.handle}</h3>
                <div className="profile-friends">
                  <FollowersDisplay userId={id} profileType="User" />
                  <FollowingDisplay userId={id} profileType="User" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="profile-main-content">
        <div className="profile-sidebar-left">
          <RoomsProfile />
          <MyImages userId={id} />
          <MyEvents />
        </div>
        <main className="profile-main-area">
          <MyPostWidgetRedux />
          <UserProfileData />
        </main>
        <aside className="profile-sidebar-right">
          <MyContentCarousel />
          <MixedSponsoredAds />
        </aside>
      </div>
    </div>
  );
};

export default PrivateProfilePage;


