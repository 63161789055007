import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { XMarkIcon } from '@heroicons/react/20/solid';
import axios from 'axios';
import { server } from "../../server";

const BannerAdProps = ({ ad }) => {
    const [isDismissed, setIsDismissed] = useState(false);
    const adRef = useRef(null); // Ref for the banner ad

    const handleDismiss = () => {
        setIsDismissed(true); 
    };

    // Function to log viewed impressions
    const logViewedImpression = async (adId) => {
        try {
            await axios.post(`${server}/sponsored/viewed-impression/${adId}`, {}, { 
                withCredentials: true, 
                headers: {
                    'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
                }
            });
            console.log(`Viewed impression logged for ad: ${adId}`);
        } catch (error) {
            console.error('Error logging viewed impression:', error);
        }
    };

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        const adId = entry.target.dataset.adId;
                        const timer = setTimeout(() => {
                            logViewedImpression(adId);
                        }, 1000);

                        entry.target._timer = timer;
                    } else {
                        clearTimeout(entry.target._timer);
                    }
                });
            },
            { threshold: 0.5 } // Trigger when 50% of the ad is in view
        );

        if (ad && adRef.current) {
            observer.observe(adRef.current);
        }

        return () => {
            observer.disconnect();
        };
    }, [ad]);

    const logAdClick = async (adId) => {
        try {
            await axios.post(`${server}/sponsored/click/${adId}`, {}, { 
                withCredentials: true, 
                headers: {
                    'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
                }
            });
        } catch (error) {
            console.error('Error logging click:', error);
        }
    };

    if (!ad || isDismissed) return null;

    const imageUrl = ad.adSet.images["Banner Ad"];
    const position = ad.adSet.positions["Banner Ad"] || { x: 0, y: 0 };
    const scale = ad.adSet.scales?.["Banner Ad"] || 1;

    
    return (
        <div className="product-banner-ad-container" ref={adRef} data-ad-id={ad._id}>
            <div className="product-banner-ad-content">
            <div className="product-banner-ad-image-container">
                {/* Gradient overlay */}
                <div className="product-banner-ad-gradient-overlay"></div>

                {/* Image */}
                <img
                    src={imageUrl}
                    alt="Product"
                    className="product-banner-ad-image"
                    style={{
                        position: 'absolute',
                        left: `${position.x}%`,
                        top: `${position.y}%`,
                        transform: `scale(${scale})`,
                        transformOrigin: 'center',
                    }}
                />
            </div>
            <div className="product-banner-ad-text">
                <Link
                    to={`/product/${ad.product._id}`}
                    onClick={() => logAdClick(ad._id)}
                    className="product-banner-ad-link"
                >
                    <strong className="product-banner-ad-strong">{ad.product.name}</strong>
                    <span> – Explore Now &rarr;</span>
                </Link>
                </div>
                <button onClick={handleDismiss} className="product-banner-ad-dismiss">
                    <XMarkIcon className="product-banner-ad-icon" aria-hidden="true" />
                    <span className="sr-only">Dismiss</span>
                </button>
            </div>
        </div>
    );
    
};

export default BannerAdProps;
