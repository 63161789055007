import React, { Fragment, useState, useEffect, useMemo } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { useSelector, useDispatch } from 'react-redux';
import { fetchMyConnections } from "../../redux/actions/connections";
import OtherFollowers from './OtherFollowers';
import OtherFollowing from './OtherFollowing';
import { setFriendListPanelOpen } from '../../state/uiSlice';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const ConnectionsPanel = () => {
  const isFriendListPanelOpen = useSelector((state) => state.ui.isFriendListPanelOpen);
  const [activeTab, setActiveTab] = useState('Followers');
  const dispatch = useDispatch();

  const currentProfile = useSelector((state) => state.user.currentProfile);
  const { user } = useSelector((state) => state.user);
  const seller = useSelector((state) => state.seller.activeSeller);
  const profileId = currentProfile === 'User' ? user?._id : seller?._id;
  const profileType = currentProfile === 'User' ? 'User' : 'Shop';

  const { hasFetchedFollowers, hasFetchedFollowing, myFollowers, myFollowing, isLoading, error } = useSelector((state) => state.connections);

  // Memoize followers and following data to avoid unnecessary re-renders
  const memoizedFollowers = useMemo(() => myFollowers, [myFollowers]);
  const memoizedFollowing = useMemo(() => myFollowing, [myFollowing]);

  // Fetch followers or following when the tab changes or if the data doesn't exist
  useEffect(() => {
    if (profileId && profileType) {
      if (activeTab === 'Followers' && !memoizedFollowers.length) {
        dispatch(fetchMyConnections(profileId, profileType, 'followers'));
      } else if (activeTab === 'Following' && !memoizedFollowing.length) {
        dispatch(fetchMyConnections(profileId, profileType, 'following'));
      }
    }
  }, [dispatch, profileId, profileType, activeTab, memoizedFollowers, memoizedFollowing]);

  const tabs = [
    { name: 'Followers', current: activeTab === 'Followers' },
    { name: 'Following', current: activeTab === 'Following' },
  ];

  const handleClose = () => {
    dispatch(setFriendListPanelOpen(false));
  };

  return (
    <Transition.Root show={isFriendListPanelOpen} as={Fragment}>
      <Dialog as="div" className="followers-following-panel-container" onClose={handleClose}>
        <div className="followers-following-panel-overlay" />
        <div className="followers-following-panel-wrapper">
          <div className="followers-following-panel-inner">
            <div className="followers-following-panel-content">
              <Transition.Child
                as="div"
                enter="followers-following-panel-enter"
                enterFrom="followers-following-panel-enter-from"
                enterTo="followers-following-panel-enter-to"
                leave="followers-following-panel-leave"
                leaveFrom="followers-following-panel-leave-from"
                leaveTo="followers-following-panel-leave-to"
                show={isFriendListPanelOpen}
              >
                <Dialog.Panel className="followers-following-panel-panel">
                  <div className="followers-following-panel-body">
                    <div className="followers-following-panel-header">
                      <Dialog.Title className="followers-following-panel-title">Social Connections</Dialog.Title>
                      <div className="followers-following-panel-close-button-wrapper">
                        <button
                          type="button"
                          className="followers-following-panel-close-button"
                          onClick={handleClose}
                        >
                          <XMarkIcon className="followers-following-panel-icon" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                    <div className="followers-following-panel-tabs">
                      <nav className="followers-following-panel-tabs-nav" aria-label="Tabs">
                        {tabs.map((tab) => (
                          <a
                            key={tab.name}
                            href="#"
                            className={classNames(
                              tab.current ? 'followers-following-panel-tab-active' : 'followers-following-panel-tab-inactive',
                              'followers-following-panel-tab'
                            )}
                            aria-current={tab.current ? 'page' : undefined}
                            onClick={() => setActiveTab(tab.name)}
                          >
                            {tab.name}
                          </a>
                        ))}
                      </nav>
                    </div>
                  </div>
                  {activeTab === 'Followers' && (
                    <OtherFollowers
                      followers={memoizedFollowers}
                      isLoading={isLoading}
                      error={error}
                    />
                  )}
                  {activeTab === 'Following' && (
                    <OtherFollowing
                      following={memoizedFollowing}
                      isLoading={isLoading}
                      error={error}
                    />
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ConnectionsPanel;




  