import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { server } from "../../server";

const SponsoredProductCarouselProps = ({ ads }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const adRefs = useRef(new Map()); // To store product refs

    const logAdClick = async (adId) => {
        try {
            await axios.post(`${server}/sponsored/click/${adId}`, { withCredentials: true, 
                headers: {
                    'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
                }});
            console.log('Click logged for ad:', adId);
        } catch (error) {
            console.error('Error logging click:', error);
        }
    };

    

    const logViewedImpression = async (adId) => {
        try {
            await axios.post(`${server}/sponsored/viewed-impression/${adId}`, {}, { withCredentials: true, 
                headers: {
                    'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
                }});
            console.log('Viewed impression logged for ad:', adId);
        } catch (error) {
            console.error('Error logging viewed impression:', error);
        }
    };

    useEffect(() => {
        if (ads.length > 0) {
            const observer = new IntersectionObserver(
                (entries) => {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting) {
                            const adId = entry.target.dataset.adId;

                            // Start a timer for 1 second to log viewed impression
                            const timer = setTimeout(() => {
                                logViewedImpression(adId);
                            }, 1000);

                            // Store the timer in the DOM element
                            entry.target._timer = timer;
                        } else {
                            clearTimeout(entry.target._timer); // Clear the timer if the element goes out of view
                        }
                    });
                },
                { threshold: 0.5 } // 50% of the ad must be visible
            );

            // Observe the current ad in view
            const currentAd = ads[currentIndex];
            const currentAdElement = adRefs.current.get(currentAd?._id);
            if (currentAdElement) {
                observer.observe(currentAdElement);
            }

            return () => {
                observer.disconnect();
            };
        }
    }, [currentIndex, ads]);

    const nextSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % ads.length);
    };

    const prevSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + ads.length) % ads.length);
    };

    if (ads.length === 0) {
        return null;
    }

    const currentAd = ads[currentIndex];
    const imageUrl = currentAd.adSet.images["Sponsored Product Carousel"];
    const position = currentAd.adSet.positions["Sponsored Product Carousel"] || { x: 0, y: 0 };
    const scale = currentAd.adSet.scales["Sponsored Product Carousel"] || 1;

    return (
        <div className="sponsored-product-carousel">
            <div
                className="sponsored-product-carousel-paper"
                data-ad-id={currentAd._id}
                ref={(el) => el && adRefs.current.set(currentAd._id, el)} 
            >
                <div className="sponsored-product-carousel-image-wrapper">
                    <img
                        src={imageUrl}
                        alt={currentAd.product.name}
                        className="sponsored-product-carousel-image"
                        style={{
                            position: 'absolute',
                            left: `${position.x}%`,
                            top: `${position.y}%`,
                            transform: `scale(${scale})`,
                            transformOrigin: 'center',
                        }}
                    />
                </div>
                <div className="sponsored-product-carousel-content">
                    <h2 className="sponsored-product-carousel-title">{currentAd.product.name}</h2>
                    <p className="sponsored-product-carousel-price">{`$${currentAd.product.discountPrice || currentAd.product.originalPrice}`}</p>
                    <Link
                        to={`/product/${currentAd.product._id}`}
                        onClick={() => logAdClick(currentAd._id)}
                        className="sponsored-product-carousel-button"
                    >
                        Buy now
                    </Link>
                </div>
            </div>
            {ads.length > 1 && (
                <>
                    <button className="sponsored-carousel-nav-button sponsored-carousel-prev" onClick={prevSlide}>‹</button>
                    <button className="sponsored-carousel-nav-button sponsored-carousel-next" onClick={nextSlide}>›</button>
                </>
            )}
        </div>
    );
};

export default SponsoredProductCarouselProps;
