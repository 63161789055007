import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { setUserLocation, locationError as setLocationError, saveLocation } from '../../redux/actions/user';

const LocationTracker = ({ onLocationRetrieved }) => {
    const location = useSelector(state => state.user.location);
    const locationError = useSelector(state => state.user.locationError);
    const dispatch = useDispatch();
    const [retry, setRetry] = useState(false);

    useEffect(() => {
        if (!location || !location.latitude || !location.longitude || retry) {
            startGeolocation();
            setRetry(false); 
        }
    }, [location, retry]);

    const startGeolocation = () => {
        const geo = navigator.geolocation;

        if (!geo) {
            dispatch(setLocationError('Geolocation is not supported by your browser'));
            return;
        }

        geo.getCurrentPosition(
            (position) => {
                const { latitude, longitude } = position.coords;
                const locationData = { latitude, longitude };
                sessionStorage.setItem('userLocation', JSON.stringify(locationData));
                dispatch(setUserLocation(locationData));
                dispatch(saveLocation(locationData));
                onLocationRetrieved(locationData); 
            },
            (error) => {
                dispatch(setLocationError(`Geolocation error: ${error.message}`));
            },
            {
                enableHighAccuracy: true,
                timeout: 5000,
                maximumAge: 0
            }
        );
    };

    return (
        <div className="location-tracker-container">
            {location && location.latitude && location.longitude ? (
                <div className="location-info">
                   
                </div>
            ) : (
                <div className="location-error-container">
                    <p className="loading-text">Loading location or an error occurred...</p>
                    {locationError && <p className="error-text">{locationError}</p>}
                    <button className="retry-button" onClick={() => setRetry(true)}>Find Events By Me!</button>
                </div>
            )}
        </div>
    );
};

export default LocationTracker;




