import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dropzone from 'react-dropzone';
import { createPost as createPostAction } from '../redux/actions/post';
import HashtagSuggestions from '../components/Hashtags/HashtagSuggestions';
import Avatar from '../components/Layout/Avatar';
import axios from 'axios';
import { server } from "../server";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faVideo } from '@fortawesome/free-solid-svg-icons';
import ImageCropperModal from '../components/Sponsored/ImageCropperModal';

const MyPostWidgetRedux = () => {
  const dispatch = useDispatch();
  const [post, setPost] = useState('');
  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);
  const [isImage, setIsImage] = useState(false);
  const [isVideo, setIsVideo] = useState(false);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [videoPreviews, setVideoPreviews] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hashtagQuery, setHashtagQuery] = useState('');
  const [showSuggestions, setShowSuggestions] = useState(false);
  const currentProfile = useSelector((state) => state.user.currentProfile);
  const userProfile = useSelector((state) => state.user);
  const sellerProfile = useSelector((state) => state.seller);
  const inputRef = useRef(null);
  const [linkPreview, setLinkPreview] = useState(null);
  const debounceTimer = useRef(null);
  const [showCropper, setShowCropper] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);

  const fetchLinkPreview = async (url) => {
    try {
      const response = await axios.post(`${server}/posts/link-preview`, { url });
      setLinkPreview(response.data);
    } catch (error) {
      console.error('Error fetching link preview:', error);
    }
  };

  const debounceFetchLinkPreview = (url) => {
    clearTimeout(debounceTimer.current);
    debounceTimer.current = setTimeout(() => {
      fetchLinkPreview(url);
    }, 500);
  };

  const toggleImageUpload = () => {
    setIsImage(!isImage);
    setIsVideo(false);
  };

  const toggleVideoUpload = () => {
    setIsVideo(!isVideo);
    setIsImage(false);
  };

  const createPost = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const formData = new FormData();
    formData.append('description', post);
    formData.append('currentProfile', currentProfile);
    if (currentProfile === 'User') {
      formData.append('userId', userProfile.id);
    } else if (currentProfile === 'Shop') {
      formData.append('userId', sellerProfile.id);
    }

    if (croppedImage) {
      formData.append('images', croppedImage);
    } else {
      images.forEach(image => {
        formData.append('images', image);
      });
    }

    videos.forEach(video => {
      formData.append('videos', video);
    });

    dispatch(createPostAction(formData))
      .then(() => {
        setIsLoading(false);
        setPost('');
        setImages([]);
        setVideos([]);
        setImagePreviews([]);
        setVideoPreviews([]);
        setLinkPreview(null);
        setIsImage(false);
        setIsVideo(false);
        setCroppedImage(null);
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error('Error creating post');
      });
  };

  useEffect(() => {
    if (images.length > 0) {
      const newPreviews = images.map(image => URL.createObjectURL(image));
      setImagePreviews(newPreviews);
      return () => newPreviews.forEach(url => URL.revokeObjectURL(url));
    } else {
      setImagePreviews([]);
    }
  }, [images]);

  useEffect(() => {
    if (videos.length > 0) {
      const newPreviews = videos.map(video => URL.createObjectURL(video));
      setVideoPreviews(newPreviews);
      return () => newPreviews.forEach(url => URL.revokeObjectURL(url));
    } else {
      setVideoPreviews([]);
    }
  }, [videos]);

  const handleInputChange = (e) => {
    const { value } = e.target;
    setPost(value);

    const lastWord = value.split(' ').pop();
    if (lastWord.startsWith('#')) {
      setHashtagQuery(lastWord.substring(1));
      setShowSuggestions(true);
    } else {
      setShowSuggestions(false);
    }

    const urlRegex = /((https?:\/\/)?(www\.)?[\w-]+\.[a-z]+(\.[a-z]+)?(\/\S*)?)/gi;
    const urlMatch = value.match(urlRegex);
    if (urlMatch) {
      let url = urlMatch[0];
      if (!url.startsWith('http://') && !url.startsWith('https://')) {
        url = 'http://' + url;
      }
      debounceFetchLinkPreview(url);
    } else {
      setLinkPreview(null);
    }
  };

  const handleHashtagSelect = (hashtag) => {
    const words = post.split(' ');
    words[words.length - 1] = `#${hashtag}`;
    setPost(words.join(' '));
    setShowSuggestions(false);
    inputRef.current.focus();
  };

  const handleRemoveImage = (index) => {
    setImages(images.filter((_, i) => i !== index));
  };

  const handleRemoveVideo = (index) => {
    setVideos(videos.filter((_, i) => i !== index));
  };

  const handleDragStart = (e, index, type) => {
    e.dataTransfer.setData('index', index);
    e.dataTransfer.setData('type', type);
  };

  const handleDrop = (e, dropIndex, type) => {
    e.preventDefault();
    const dragIndex = parseInt(e.dataTransfer.getData('index'), 10);
    const itemType = e.dataTransfer.getData('type');

    if (itemType === type) {
      if (type === 'image') {
        const reorderedImages = [...images];
        const [draggedImage] = reorderedImages.splice(dragIndex, 1);
        reorderedImages.splice(dropIndex, 0, draggedImage);
        setImages(reorderedImages);
      } else if (type === 'video') {
        const reorderedVideos = [...videos];
        const [draggedVideo] = reorderedVideos.splice(dragIndex, 1);
        reorderedVideos.splice(dropIndex, 0, draggedVideo);
        setVideos(reorderedVideos);
      }
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleFileInputChange = (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      setSelectedFile(acceptedFiles[0]);
      setShowCropper(true);
    }
  };

  const updateCroppedImage = (croppedFile) => {
    setCroppedImage(croppedFile);
    setImages([croppedFile]);
    setShowCropper(false);
  };

  return (
    <>
      <ToastContainer />
      <div className="my-post-widget-wrapper">
        <div className="my-post-widget-flex-between" style={{ gap: '1.5rem' }}>
          <Avatar />
          <input
            type="text"
            placeholder="What's on your mind..."
            onChange={handleInputChange}
            value={post}
            className="my-post-widget-input-base"
            ref={inputRef}
          />
        </div>

        {showSuggestions && (
          <HashtagSuggestions query={hashtagQuery} onSelect={handleHashtagSelect} />
        )}

        {linkPreview && (
          <div className="my-post-widget-link-post-preview-container" style={{ marginTop: '1rem' }}>
            {linkPreview.images?.[0] && (
              <img src={linkPreview.images[0]} alt="Preview" className="my-post-widget-link-post-preview-image" />
            )}
            <div className="my-post-widget-link-post-preview-content">
              <h6 className="my-post-widget-link-post-preview-title">{linkPreview.title}</h6>
              <p className="my-post-widget-link-post-preview-description">{linkPreview.description}</p>
              <p className="my-post-widget-link-post-preview-url">{linkPreview.url}</p>
            </div>
          </div>
        )}

        <hr className="my-post-widget-divider" />

        <div className="my-post-widget-flex-between my-post-widget-image-video-container">
          <div className="my-post-widget-flex-between" style={{ gap: '0.25rem' }} onClick={toggleImageUpload}>
            <FontAwesomeIcon className="my-post-widget-icon" icon={faCamera} />
            <p className="my-post-widget-typography">Image</p>
          </div>

          <div className="my-post-widget-flex-between" style={{ gap: '0.25rem' }} onClick={toggleVideoUpload}>
            <FontAwesomeIcon className="my-post-widget-icon" icon={faVideo} />
            <p className="my-post-widget-typography">Video</p>
          </div>

          <button
            className="my-post-widget-post-button"
            disabled={(!post.trim() && images.length === 0 && videos.length === 0) || isLoading}
            onClick={createPost}
          >
            {isLoading ? 'Loading...' : 'POST'}
          </button>
        </div>

        {isImage && (
          <div className="my-post-widget-image-dropzone-container">
            <Dropzone
              acceptedFiles=".jpg,.jpeg,.png"
              multiple={true}
              onDrop={(acceptedFiles) => handleFileInputChange(acceptedFiles)}
            >
              {({ getRootProps, getInputProps }) => (
                <div className="my-post-widget-dropzone-inner">
                  <div {...getRootProps()} className="my-post-widget-dropzone-box">
                    <input {...getInputProps()} />
                    <p>{images.length > 0 ? `${images.length} image(s) selected` : 'Add Image(s) Here'}</p>
                  </div>
                </div>
              )}
            </Dropzone>
            {imagePreviews.length > 0 && (
              <div className="my-post-widget-image-preview-container">
                {imagePreviews.map((preview, index) => (
                  <div
                    key={index}
                    className="my-post-widget-flex-between draggable"
                    draggable
                    onDragStart={(e) => handleDragStart(e, index, 'image')}
                    onDragOver={handleDragOver}
                    onDrop={(e) => handleDrop(e, index, 'image')}
                  >
                    <img src={preview} alt="Preview" className="my-post-widget-image-preview" />
                    <button
                      onClick={() => handleRemoveImage(index)}
                      className="my-post-widget-delete-icon-button"
                    >
                      🗑️
                    </button>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}

        {isVideo && (
          <div className="my-post-widget-video-dropzone-container">
            <Dropzone
              acceptedFiles="video/*"
              multiple={true}
              onDrop={(acceptedFiles) => setVideos([...videos, ...acceptedFiles])}
            >
              {({ getRootProps, getInputProps }) => (
                <div className="my-post-widget-dropzone-inner">
                  <div {...getRootProps()} className="my-post-widget-dropzone-box">
                    <input {...getInputProps()} />
                    <p>{videos.length > 0 ? `${videos.length} video(s) selected` : 'Add Video(s) Here'}</p>
                  </div>
                </div>
              )}
            </Dropzone>
            {videoPreviews.length > 0 && (
              <div className="my-post-widget-video-preview-container">
                {videoPreviews.map((preview, index) => (
                  <div
                    key={index}
                    className="my-post-widget-flex-between draggable"
                    draggable
                    onDragStart={(e) => handleDragStart(e, index, 'video')}
                    onDragOver={handleDragOver}
                    onDrop={(e) => handleDrop(e, index, 'video')}
                  >
                    <video className="my-post-widget-video-preview" controls>
                      <source src={preview} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                    <button
                      onClick={() => handleRemoveVideo(index)}
                      className="my-post-widget-delete-icon-button"
                    >
                      🗑️
                    </button>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>

      {showCropper && (
        <ImageCropperModal
          updateAvatar={updateCroppedImage}
          closeModal={() => setShowCropper(false)}
          initialImage={selectedFile}
          aspectRatio={4 / 5}
          minWidth={250}
          isAvatar={false}
        />
      )}
    </>
  );
};

export default MyPostWidgetRedux;



