import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { server } from "../../server";
import Loader from "../Layout/Loader";
import { AiOutlineCamera } from "react-icons/ai";
import { loadUser } from "../../redux/actions/user";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import ImageCropperModal from "../Sponsored/ImageCropperModal";

const UserAvatar = ({ isUser, user, setUser }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [userAvatar, setUserAvatar] = useState(user.avatar?.url || null);
  const [showAvatarCropper, setShowAvatarCropper] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [croppedFile, setCroppedFile] = useState(null);
  const [showSaveButton, setShowSaveButton] = useState(false);
  const currentProfile = sessionStorage.getItem("currentProfile");
  const dispatch = useDispatch();

  const handleAvatarChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      setShowAvatarCropper(true); // Show cropper modal when avatar image is selected
    }
  };

  const updateAvatar = (croppedFile) => {
    setCroppedFile(croppedFile); // Store the cropped avatar file
    const previewUrl = URL.createObjectURL(croppedFile);
    setUserAvatar(previewUrl);
    setShowSaveButton(true); // Show save button when a new image is uploaded
  };

  const handleSaveAvatar = async () => {
    try {
      const formData = new FormData();
      formData.append("avatar", croppedFile);

      await axios.put(
        `${server}/user/update-avatar`,
        formData,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "multipart/form-data",
            "Current-Profile": currentProfile,
          },
        }
      );
      dispatch(loadUser());
      toast.success("Avatar updated successfully!");
      setShowSaveButton(false); // Hide save button after saving
      setUser({ ...user, avatar: { url: userAvatar } });
    } catch (error) {
      toast.error(error.response?.data?.message || "Failed to update avatar");
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="profile-avatar-wrapper">
      <img
        src={userAvatar || 'default-avatar.png'}
        alt={user.name}
        className="profile-avatar"
      />
      {isUser && (
        <div className="profile-upload-container">
          <input
            type="file"
            id="avatar"
            className="hidden"
            onChange={handleAvatarChange}
          />
          <label htmlFor="avatar">
            <AiOutlineCamera className="user-avatar-camera-icon" />
          </label>
          {showSaveButton && (
            <button
              className="user-avatar-save-button"
              onClick={handleSaveAvatar}
              disabled={!croppedFile}
            >
              Save
            </button>
          )}
        </div>
      )}

      {/* Avatar Image Cropper Modal */}
      {showAvatarCropper && (
        <ImageCropperModal
          updateAvatar={updateAvatar}
          closeModal={() => setShowAvatarCropper(false)}
          initialImage={selectedFile}
          aspectRatio={1}
          minWidth={200}
          isAvatar={true}
        />
      )}
    </div>
  );
};

export default UserAvatar;