import React, { useState, useEffect } from 'react';
import LocationTracker from '../components/Location/Locator';
import EventsPage from './EventsPage';

const EventsPageContainer = () => {
    const [userLocation, setUserLocation] = useState(null);

    useEffect(() => {
        const savedLocation = sessionStorage.getItem('userLocation');
        if (savedLocation) {
            setUserLocation(JSON.parse(savedLocation));
        }
    }, []);

    const handleLocationRetrieved = (location) => {
        setUserLocation(location);
    };

    return (
        <>
            <LocationTracker onLocationRetrieved={handleLocationRetrieved} />
            <EventsPage userLocation={userLocation} />
        </>
    );
};

export default EventsPageContainer;

