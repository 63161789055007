import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import socketIOClient from 'socket.io-client';
import { UserCircleIcon, ChatBubbleLeftEllipsisIcon, NewspaperIcon, UserPlusIcon, ShareIcon } from '@heroicons/react/20/solid';
import { backend_url } from '../server';
import { Link } from 'react-router-dom';
import { fetchSinglePost } from '../redux/actions/post'; 
import SinglePostWidget from '../widgets/SinglePostWidget';

const ENDPOINT = backend_url;
const socket = socketIOClient(ENDPOINT, { withCredentials: true });

function NotificationFeed({ profileId, profileType }) {
  const dispatch = useDispatch();
  const { singlePost, loading, error } = useSelector((state) => state.posts); 
  const [notifications, setNotifications] = useState([]);
  const [selectedPostId, setSelectedPostId] = useState(null);

  const getIconForType = (type) => {
    switch (type) {
      case 'newComment':
        return <ChatBubbleLeftEllipsisIcon className="notification-feed-icon" aria-hidden="true" />;
        case 'newLike':
          return (
            <img
              src="https://res.cloudinary.com/dzlopmfj8/image/upload/v1724845531/icon-BuzzBolt-02_ernlpk.png"
              alt="Like Notification"
              className="notification-feed-icon"
            />
          );
      case 'newPost':
        return <NewspaperIcon className="notification-feed-icon" aria-hidden="true" />;
      case 'follow':
        return <UserPlusIcon className="notification-feed-icon" aria-hidden="true" />;
      case 'reshare':
        return <ShareIcon className="notification-feed-icon" aria-hidden="true" />;
      default:
        return null;
    }
  };

  useEffect(() => {
    // Fetch notifications
    const fetchNotifications = async () => {
      try {
        const response = await fetch(`${ENDPOINT}/api/v2/notifications/get-notifications/${profileType}/${profileId}`);
        const data = await response.json();
        setNotifications(data.notifications);
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    };

    fetchNotifications();

    socket.on('notification', (newNotification) => {
      setNotifications(prevNotifications => [newNotification, ...prevNotifications]);
    });

    return () => socket.off('notification');
  }, [profileId, profileType]);

  const getProfileLink = (senderProfileType, sender) => {
    if (senderProfileType === 'User') {
      return `/profile/${sender._id}`;
    } else if (senderProfileType === 'Shop') {
      return `/shop/${sender.handle}`;
    }
    return '#';
  };

  const handleOpenPostModal = (postId) => {
    setSelectedPostId(postId);
    dispatch(fetchSinglePost(postId)); 
  };

  return (
    <div className="notification-feed-flow-root">
      {notifications.length === 0 ? (
        <div className="notification-feed-empty">
          <p className="notification-feed-empty-message">You don't have any recent notifications</p>
        </div>
      ) : (
        <ul role="list" className="notification-feed-list">
          {notifications.map((notification, idx) => (
            <li key={notification._id}>
              <div className="notification-feed-item">
                {idx !== notifications.length - 1 && (
                  <span className="notification-feed-connector" aria-hidden="true" />
                )}
                <div className="notification-feed-content-wrapper">
                  <div className="notification-feed-icon-wrapper">
                    {getIconForType(notification.type)}
                    {notification.sender && (
                      <img
                        className="notification-feed-avatar"
                        src={notification.sender.avatar.url}
                        alt="Sender avatar"
                      />
                    )}
                  </div>
                  <div className="notification-feed-text-wrapper">
                  <div className="notification-feed-text">
                    <p>
                      <Link to={getProfileLink(notification.senderProfileType, notification.sender)} className="notification-feed-link">
                        <strong>{notification.sender.name}</strong>
                      </Link>
                    </p>
                    <p>{notification.message}</p>
                    <p>{new Date(notification.createdAt).toLocaleString()}</p>
                    </div>

                    {notification.relatedPost && (
                    <button
                        className="notifcations-view-post-button"
                        onClick={() => handleOpenPostModal(notification.relatedPost._id)}
                    >
                        View Post
                    </button>
                    )}

                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      )}

      {selectedPostId && singlePost && (
        <div className="notification-post-view-modal open">
          <div className="notification-post-view-backdrop" onClick={() => setSelectedPostId(null)}></div>
          <div className="notification-post-view-modal-content">
            <button className="notification-post-view-close-btn" onClick={() => setSelectedPostId(null)}>
              X
            </button>
            <div className="notification-post-view-post-content">
              <SinglePostWidget
                postId={singlePost._id}
                owner={singlePost.owner}
                postUserId={singlePost.userId}
                profileType={singlePost.profileType}
                name={singlePost.name}
                description={singlePost.description}
                location={singlePost.location}
                images={singlePost.images}
                videos={singlePost.videos}
                avatar={singlePost.avatar}
                likes={singlePost.likes}
                comments={singlePost.comments}
                createdAt={singlePost.createdAt}
                resharedBy={singlePost.resharedBy}
                reshareDescription={singlePost.reshareDescription}
                reshareAvatar={singlePost.reshareAvatar}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default NotificationFeed;










